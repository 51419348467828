import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ElementChip } from './ElementChip';
import { useTelemetryContext } from '../../context/TelemetryContext';
import { Jumps } from '../../lib/Elements';

export function JumpPicker({index}) {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [jumps, setJumps] = useState(telemetryState.programElements[index].elements);

    const handleShowMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setShowMenu(true);
    }

    const themeColor = 'jump';

    const handleDelete = (elIndex) => {
        const myJumps = jumps.filter((v, i) => {
            return i !== elIndex;
        });
        setJumps(myJumps);
        const myElements = [...telemetryState.programElements];
        myElements[index].elements = myJumps;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    const updateJumps = (jumps) => {
        setJumps(jumps);
        const myElements = [...telemetryState.programElements];
        myElements[index].elements = jumps;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    return (
        <Stack direction='row' className='element-card-elements'>
            {jumps.map((el, i) => {
                return (
                    <ElementChip
                        element={el}
                        programIndex={index}
                        elementIndex={i}
                        onDelete={() => handleDelete(i)}
                        themeColor={themeColor}
                        key={`element-${i}`}
                    />
                )
            })}
            {jumps.length < 5 && <AddCircleIcon color={themeColor} onClick={handleShowMenu} />}
            <JumpPopover anchorEl={anchorEl} open={showMenu} setOpen={setShowMenu} jumps={jumps} setJumps={updateJumps} />
        </Stack>
    );
}

function JumpPopover({anchorEl, open, setOpen, jumps, setJumps}) {
    const handleClose = () => {
        setOpen(false);
    }

    const buildMenuItems = () => {
        const items = [];
        Jumps.forEach((value, key, index) => {
            items.push(<MenuItem disabled={true} dense key={`${key}-rotation`}>{key} Rotations</MenuItem>);
            value.forEach((v, k, i) => {
                items.push(<MenuItem dense onClick={() => { handleElementClick(k, v) }} key={`${k}-${v.code}`}><span className="element-label">{v.code}</span> {k}</MenuItem>);
            });
        });

        return items;
    }

    const handleElementClick = (key, value) => {
        const myJumps = [...jumps, {name: key, ...value}];
        setJumps(myJumps);
        setOpen(false);
    }

    return (
        <Menu
            id="time-index-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            sx={{maxHeight: '500px'}}
            children={buildMenuItems()}
        >
        </Menu>
    )
}