import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useTelemetryContext } from '../../context/TelemetryContext';
import { convertSecondsToTimeString } from '../../lib/DataHelpers';
import RinkDimensions from '../../lib/RinkDimensions';

export const CanvasTelemetry = () => {
  const { state: telemetryState, dispatch: telemetryDispatch } = useTelemetryContext();
  const [programLength, setProgramLength] = useState('00:00');
  const [showSpeedMenu, setShowSpeedMenu] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [rinkMenuAnchor, setRinkMenuAnchor] = useState(null);
  const [showRinkMenu, setShowRinkMenu] = useState(false);

  const handleSpeedChange = (event) => {
    closeSpeedMenu();
    telemetryDispatch({type: 'UPDATE_METADATA', value: {programSpeed: event.target.value}})
  };

  const handleShowSpeedMenu = (event) => {
    setMenuAnchor(event.currentTarget);
    setShowSpeedMenu(true);
  }

  const closeSpeedMenu = () => {
    setShowSpeedMenu(false);
  }

  const handleShowRinkMenu = (event) => {
    setRinkMenuAnchor(event.currentTarget);
    setShowRinkMenu(true);
  }

  const closeRinkMenu = () => {
    setShowRinkMenu(false);
  }

  const handleRinkChange = (event) => {
    closeRinkMenu();
    telemetryDispatch({type: 'UPDATE_METADATA', value: {rinkSize: event.target.getAttribute('value')}})
  }

  const averageSpeed = [];
  averageSpeed.length = 15;
  averageSpeed.fill(1);

  useEffect(() => {
    const calculateProgramLength = () => {
        let timeInSeconds = (telemetryState.metadata.programLength / (telemetryState.metadata.programSpeed * 1.46667));
        telemetryState.programElements.filter(el => el.type === 'Spin').forEach(element => {
          element.elements.forEach(el => {
            if (el.time) {
              timeInSeconds += el.time;
            }
          })
        })
        return convertSecondsToTimeString(timeInSeconds);
    }

    setProgramLength(calculateProgramLength());
  }, [telemetryState.metadata.programSpeed, telemetryState.metadata.programLength, telemetryState.programElements]);

  return (
    <Box sx={{margin: '0 auto 10px', justifySelf: 'flex-end'}}>
        <Stack direction="row" spacing={3} sx={{fontWeight: 'bold'}}>
            <Chip label={`Avg. Speed: ${telemetryState.metadata.programSpeed} mph`} onClick={handleShowSpeedMenu} />
            <Chip label={`Program Length: ${programLength}`} />
            <Chip label={`Song Length: ${convertSecondsToTimeString(telemetryState.metadata.songLength)}`} />
            <Chip label={`Rink Size: ${RinkDimensions.get(telemetryState?.metadata?.rinkSize ?? 'default').label}`} onClick={handleShowRinkMenu} />
        </Stack>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          open={showSpeedMenu}
          onClose={closeSpeedMenu}
        >
          {averageSpeed.map((val, i) => {
            return <MenuItem dense value={i+1} key={i} onClick={handleSpeedChange}>{i+1} mph</MenuItem>
          })}
        </Menu>

        <Menu
          anchorEl={rinkMenuAnchor}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          open={showRinkMenu}
          onClose={closeRinkMenu}
        >
          {[...RinkDimensions.keys()].map(key => {
            return (
              <MenuItem dense value={key} key={key} onClick={handleRinkChange}>{RinkDimensions.get(key).label}</MenuItem>
            )
          })}
        </Menu>
    </Box>
  );
};