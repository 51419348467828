import React, { useRef, useEffect } from 'react';
import paper from 'paper';
import PaperApp from '../../Paper/PaperApp';
import { useAppContext } from '../../context/AppContext';
import { useTelemetryContext } from '../../context/TelemetryContext';
import RinkDimensions from '../../lib/RinkDimensions';

const PaperCanvas = () => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const { state: telemetryState, dispatch: telemetryDispatch } = useTelemetryContext();

  const canvasRef = useRef(null);
  const Paper = useRef(null);

  const canvasWidth = RinkDimensions.get(telemetryState?.metadata?.rinkSize ?? 'default').width * appState.globalScale;
  const canvasHeight = RinkDimensions.get(telemetryState?.metadata?.rinkSize ?? 'default').height * appState.globalScale;

  useEffect(() => {
    // Setup Paper.js on the canvas element
    paper.setup(canvasRef.current);
    Paper.current = PaperApp(paper.project);
    //Paper.current.drawRink();
    paper.project.updateTelemetry = telemetryDispatch;

    appDispatch({type: 'SET_PAPER_PROJECT', value: paper.project})

    // Clean up Paper.js resources when the component unmounts
    return () => {
      paper.project.clear();
      paper.view.remove();
    };
  }, [appDispatch, telemetryDispatch]);


  const showState = () => {
    let className = '';
    switch (appState.canvasVisible) {
      case true:
        className = "open";
        break;
      case false:
        className = "closed";
        break;
      default:
        className = '';
    }

    return className;
  }

  useEffect(() => {
    if (appState.paper && telemetryState.programPath) {
      appState.paper.API.importProgramPath(telemetryState.programPath);
    }
  }, [appState.paper, telemetryState.programPath]);

  return (
    <div id="canvas-wrapper" className={showState()}>
      <div id="canvas-frame" className={telemetryState.metadata.rinkSize}>
        <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      </div>
    </div>
  );
};

export default PaperCanvas;