import { Path } from 'paper';
import { findNearestPoint } from '../PathHelpers';

export const SegmentControl = (source, project) => {
    const programPath = project.layers['Program'].children['Program'];

    const control = new Path.Circle({
        center: source.point,
        radius: 7.5,
        strokeWidth: 2,
        strokeColor: 'black',
        fillColor: 'white',
        source: null
    });

    control.onMouseDrag = (event) => {
        control.position = event.point;
        control.source.point = event.point;
    }

    control.onMouseUp = (event) => {
        control.source.path.smooth({type: 'catmull-rom', factor: 0, from: control.source.previous, to: control.source.next});
        programPath.project.layers['Program'].updateTokens();
        project.updateTelemetry({type: 'UPDATE_PROGRAM_PATH', value: programPath.exportJSON({asString: false})});
        project.updateTelemetry({type: 'UPDATE_METADATA', value: { programLength: programPath.length / project.globalScale }});
    }

    control.onMouseDown = (event) => {
        const nearestSegment = findNearestPoint(control.position, programPath.segments);
        control.source = nearestSegment;
    }

    control.onMouseEnter = (event) => {
        control.set({
            fillColor: 'black'
        });
    }

    control.onMouseLeave = (event) => {
        if (!control.selected) {
            control.set({
                fillColor: 'white'
            });
        }
    }

    control.onDoubleClick = (event) => {
        control.selected = !control.selected;
    }

    return control;
}