export const Jumps = new Map([
    ['.5', new Map([
        ['Half Flip', { code: '', rotations: .5, time: .5 }],
        ['Half Loop', { code: 'Euler', rotations: .5, time: .5 }],
        ['Half Lutz', { code: '', rotations: .5, time: .5 }],
        ['Waltz Jump', { code: '', rotations: .5, time: .5 }],
    ])],
    ['1', new Map([
        ['Axel', { code: '1A', rotations: 1, time: .5 }],
        ['Flip', { code: '1F', rotations: 1, time: .5 }],
        ['Loop', { code: '1Lo', rotations: 1, time: .5 }],
        ['Lutz', { code: '1Lz', rotations: 1, time: .5 }],
        ['Salchow', { code: '1S', rotations: 1, time: .5 }],
        ['Toe Loop', { code: '1T', rotations: 1, time: .5 }],
    ])],
    ['2', new Map([
        ['Axel', { code: '2A', rotations: 2, time: 1 }],
        ['Flip', { code: '2F', rotations: 2, time: 1 }],
        ['Loop', { code: '2Lo', rotations: 2, time: 1 }],
        ['Lutz', { code: '2Lz', rotations: 2, time: 1 }],
        ['Salchow', { code: '2S', rotations: 2, time: 1 }],
        ['Toe Loop', { code: '2T', rotations: 2, time: 1 }],
    ])],
    ['3', new Map([
        ['Axel', { code: '3A', rotations: 3, time: 1.5 }],
        ['Flip', { code: '3F', rotations: 3, time: 1.5 }],
        ['Loop', { code: '3Lo', rotations: 3, time: 1.5 }],
        ['Lutz', { code: '3Lz', rotations: 3, time: 1.5 }],
        ['Salchow', { code: '3S', rotations: 3, time: 1.5 }],
        ['Toe Loop', { code: '3T', rotations: 3, time: 1.5 }],
    ])],
    ['4', new Map([
        ['Axel', { code: '4A', rotations: 4, time: 2 }],
        ['Flip', { code: '4F', rotations: 4, time: 2 }],
        ['Loop', { code: '4Lo', rotations: 4, time: 2 }],
        ['Lutz', { code: '4Lz', rotations: 4, time: 2 }],
        ['Salchow', { code: '4S', rotations: 4, time: 2 }],
        ['Toe Loop', { code: '4T', rotations: 4, time: 2 }],
    ])],
]);

export const Spins = new Map([
    ['Camel Spin', { code: 'CSp', time: 5}],  
    ['Change Foot Camel Spin', { code: 'CCSp', time: 5}],  
    ['Change Foot Combination Spin', { code: 'CCoSp', time: 5}],  
    ['Change Foot Layback Spin', { code: 'CLSp', time: 5}],  
    ['Change Foot Sit Spin', { code: 'CSSp', time: 5}],  
    ['Change Foot Upright Spin', { code: 'CUSp', time: 5}],  
    ['Combination Spin', { code: 'CoSp', time: 5}],  
    ['Flying Camel Spin', { code: 'FCSp', time: 5}],  
    ['Flying Change Foot Camel Spin', { code: 'FCCSp', time: 5}],  
    ['Flying Change Foot Comb. Spin', { code: 'FCCoSp', time: 5}],  
    ['Flying Change Foot Layback Spin', { code: 'FCLSp', time: 5}],  
    ['Flying Change Foot Sit Spin', { code: 'FCSSp', time: 5}],  
    ['Flying Change Foot Upright Spin', { code: 'FCUSp', time: 5}],  
    ['Flying Combination Spin', { code: 'FCoSp', time: 5}],  
    ['Flying Layback Spin', { code: 'FLSp', time: 5}],  
    ['Flying Sit Spin', { code: 'FSSp', time: 5}],  
    ['Flying Upright Spin', { code: 'FUSp', time: 5}],  
    ['Layback Spin', { code: 'LSp', time: 5}],  
    ['Sit Spin', { code: 'SSp', time: 5}],  
    ['Upright Spin', { code: 'USp', time: 5}],  
]);

export const Steps = new Map([
    ['Choreo Sequence', { code: 'ChSq', time: 10 }],
    ['Step Sequence', { code: 'StSq', time: 10 }]
]);

export const Other = new Map([
    ['Split Jump', { code: '', rotations: 0, time: 0 }],
]);