import React from 'react';

import BottomNavigation from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Footer = () => {
    return (
        <BottomNavigation
            sx={{
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#0E2858',
                padding: '15px',
                color: '#FFF',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: '50px',
                maxWidth: '100% !important',
                columnGap: '15px',
                zIndex: 2,
            }}>
            <img src="/assets/logo.svg" height="50" />
            <Typography variant="body">&copy; 2024 LesterLogic.com. All rights reserved.</Typography>
        </BottomNavigation>
    )
}