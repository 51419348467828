import { Boundary } from './Layers/Boundary';
import { HockeyLines } from './Layers/HockeyLines';
import { GridLines } from './Layers/GridLines';
import { Program } from './Layers/Program';
import { ProgramElements } from './Layers/ProgramElements';
import { AudioElements } from './Layers/AudioElements';
import { Draw } from './Layers/Draw';
import { Edit } from './Layers/Edit';
import { Run } from './Layers/Run';

import { addElements } from "./utils/elementLayer";
import GestureMouseEvents from '../lib/interactions/gestureMouseEvents';
import LineMouseEvents from '../lib/interactions/lineMouseEvents';

import RinkDimensions from '../lib/RinkDimensions';

const PaperApp = (project) => {
    project.telemetryState = {};
    project.appConfig = {};
    project.globalScale = 7; // Define standard dimensions of an ice rink (in meters)
    project.rinkWidth = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').width * project.globalScale; // Width of the rink
    project.rinkHeight = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').height * project.globalScale; // Length of the rink
    project.rinkRadius = 28 * project.globalScale; // Radius of the rounded corners
    project.drawMode = '';
    project.timingElements = [];

    Boundary(project);
    HockeyLines(project);
    GridLines(project);
    Program(project);
    ProgramElements(project);
    AudioElements(project);
    Draw(project);
    Edit(project);
    Run(project);

    const gestureEvents = GestureMouseEvents(project);
    const lineEvents = LineMouseEvents(project);

    project.setDrawMode = (mode) => {
        if (mode === project.drawMode) return;
        project.drawMode = mode;

        project.layers["Program Elements Layer"].opacity = 1;
        project.layers["Audio Elements Layer"].opacity = 1;

        switch (mode) {
            case 'gesture':
                project.layers['Edit'].visible = false;
                project.layers['Draw'].visible = true;
                project.layers['Draw'].off(lineEvents);
                project.layers['Draw'].on(gestureEvents);
                project.layers['Draw'].activate();
                break;
            case 'line':
                project.layers['Edit'].visible = false;
                project.layers['Draw'].visible = true;
                project.layers['Draw'].off(gestureEvents);
                project.layers['Draw'].on(lineEvents);
                project.layers['Draw'].activate();
                break;
            case 'edit':
                project.layers['Draw'].visible = false;
                project.layers['Edit'].visible = true;
                project.layers['Edit'].addSegmentControls(project);
                project.layers['Edit'].activate();

                project.layers["Program Elements Layer"].opacity = .75;
                project.layers["Audio Elements Layer"].opacity = .75;
                break;
            case 'run':
                project.layers['Run'].visible = true;
                project.layers['Draw'].visible = false;
                project.layers['Edit'].visible = false;
                project.layers['Run'].activate();
                break;
            default:
        }
    }

    project.deleteEvent = () => {
        switch (project.drawMode) {
            case 'edit':
                project.layers['Edit'].deleteEvent();
                break;
            default:
                project.layers['Program'].deleteEvent();
        }
    }

    project.importProgramPath = (pathData) => {
        project.layers['Program'].children['Program'].removeSegments();
        project.layers['Program'].children['Program'].importJSON(pathData);
        project.layers['Program'].updateTokens();
    }

    project.updateProjectData = (data) => {
        project.telemetryState = data;
        setTimingElements();
    }

    project.updateAppConfig = (config) => {
        project.appConfig = config;
    }

    const setTimingElements = () => {
        const timingElements = [];
        let accumulatedOffset = 0;

        project.telemetryState.programElements.forEach(element => {
            element.elements.forEach((el, i) => {
                if (i > 0) {
                    timingElements.push({timeIndex: element.time + (element.elements[i - 1].time + .5), offset: el.time, name: el.name, code: el.code, type: element.type, adjustedTime: 0});
                } else {
                    timingElements.push({timeIndex: element.time, offset: el.time, name: el.name, code: el.code, type: element.type, adjustedTime: 0});
                }
            });
        });
        Object.keys(project.telemetryState.songAnnotations).forEach(anno => {
            timingElements.push({timeIndex: parseInt(anno), offset: 0, type: 'Song', adjustedTime: 0});
        });

        timingElements.sort((a, b) => a.timeIndex - b.timeIndex);

        timingElements.map((el, i) => {
            el.adjustedTime = el.timeIndex - accumulatedOffset;
            if (i > 0 && el.adjustedTime < timingElements[i - 1].adjustedTime) {
                el.adjustedTime = timingElements[i - 1].adjustedTime;
            }
            if (el.type === 'Spin') accumulatedOffset += el.offset;
            return el;
        });

        project.timingElements = timingElements;
        addElements(project);
    }

    project.API = {
        setDrawMode: project.setDrawMode,
        importProgramPath: project.importProgramPath,
        updateProjectData: project.updateProjectData,
        delete: project.deleteEvent,
    }

    project.setDrawMode('gesture');

    return project;
}

export default PaperApp;