import { Path, Point, Segment } from 'paper';

const onMouseDrag = (event, drawPath) => {
    const eventPoint = new Point(event.point);

    drawPath.lastSegment.point = eventPoint;

    return false;
}

const onMouseDown = (event, programPath, drawPath) => {
    const lastSegment = programPath.lastSegment;
    const startPoint = new Point(event.point);

    if (lastSegment) {
        const pathAnchor = new Path.Circle({
            center: [lastSegment.point.x, lastSegment.point.y],
            radius: 5,
            strokeWidth: 2,
            strokeColor: 'black'
        });
        pathAnchor.removeOnUp();
        drawPath.addSegments([new Segment([lastSegment.point.x, lastSegment.point.y], null, null), new Segment(startPoint, null, null)]);
    } else {
        const pathAnchor = new Path.Circle({
            center: startPoint, 
            radius: 5,
            strokeWidth: 2,
            strokeColor: 'black'
        });
        pathAnchor.removeOnUp();
        drawPath.addSegments([new Segment(startPoint, null, null), new Segment(startPoint, null, null)]);
    }
}

const onMouseUp = (programPath, drawPath) => {
    programPath.join(drawPath.clone());
    programPath.project.layers['Program'].updateTokens();
    programPath.smooth({type: 'catmull-rom', factor: 0, from: -3, to: -1});
    programPath.project.updateTelemetry({type: 'UPDATE_PROGRAM_PATH', value: programPath.exportJSON({asString: false})});
    programPath.project.updateTelemetry({type: 'UPDATE_METADATA', value: { programLength: programPath.length / programPath.project.globalScale }});

    drawPath.removeSegments();
}

const LineMouseEvents = (project) => {
    const drawPath = project.layers['Draw'].children['Draw']
    const programPath = project.layers['Program'].children['Program'];
    
    return {
        mousedown: (event) => { onMouseDown(event, programPath, drawPath); },
        mouseup: (event) => { onMouseUp(programPath, drawPath); },
        mousedrag: (event) => { onMouseDrag(event, drawPath); },
    }
}

export default LineMouseEvents;