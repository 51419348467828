import { Layer, Path, Point } from 'paper';

import RinkDimensions from '../../lib/RinkDimensions';

export const Run = (project) => {
    const layer = new Layer({
        name: "Run",
        visible: false
    });

    const width = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').width * project.globalScale;
    const height = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').height * project.globalScale;

    const skaterToken = new Path.RegularPolygon({
        name: 'Token',
        center: new Point(width/2, height/2),
        sides: 3,
        radius: 16,
        fillColor: '#DE62E4',
        strokeColor: '#B821C0',
        strokeWidth: 2,
    });
    skaterToken.applyMatrix = false;

    skaterToken.onFrame = (event) => {
        if (!layer.visible) return;

        const programPath = project.layers['Program'].children['Program'];
        const scaleMultiplier = project.globalScale * project.telemetryState.metadata?.programSpeed * 1.466667;
        const wavesurfer = project.appConfig.wavesurfer;
        const timingPoints = [];
        let accumulatedOffset = 0;
        let minTime = 0;
        let useTime = 0;

        project.timingElements.filter(el => el.type === 'Spin').forEach(el => {
            timingPoints.push({ start: el.adjustedTime, offset: el.offset });
        });

        if (wavesurfer?.isPlaying) {
            const currentTime = wavesurfer.getCurrentTime();

            timingPoints.forEach(tp => {
                if ((currentTime - accumulatedOffset) >= tp.start) {
                    accumulatedOffset += tp.offset;
                    minTime = tp.start;
                }
            });

            useTime = currentTime - accumulatedOffset;
            if (useTime < minTime) {
                useTime = minTime;
            }
        }
        const newPoint = programPath.getPointAt(useTime * scaleMultiplier ?? 0) ?? programPath.getPointAt(programPath.length);
        const tangent = programPath.getTangentAt(useTime * scaleMultiplier ?? 0) ?? programPath.getTangentAt(programPath.length);
        const angle = tangent.angle;
        skaterToken.rotation = angle - 27;

        skaterToken.position = newPoint;
    }

    layer.addChild(skaterToken);
    project.addLayer(layer);
}