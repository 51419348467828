import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useTelemetryContext } from '../../context/TelemetryContext';
import { convertSecondsToTimeString } from '../../lib/DataHelpers';

export const AudioMetadata = () => {
    const { state: telemetryState, dispatch } = useTelemetryContext();

    const updateSongTitle = (event) => {
        dispatch({type: 'UPDATE_METADATA', value: { songTitle: event.target.value }});
    }

    const updateSongArtist = (event) => {
        dispatch({type: 'UPDATE_METADATA', value: { songArtist: event.target.value }});
    }

    return (
        <Stack direction='row' sx={{marginBottom: '25px', justifyContent: 'space-between'}} useFlexGap={true}>
            <TextField variant='standard' label='Title' size="small" onChange={updateSongTitle} value={telemetryState.metadata.songTitle} />
            <TextField variant='standard' label='Artist' size="small" onChange={updateSongArtist} value={telemetryState.metadata.songArtist} />
            <TextField variant='standard' label='Length' size="small" disabled value={convertSecondsToTimeString(telemetryState.metadata.songLength)} />
        </Stack>
    )
}