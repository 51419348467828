import { createTheme } from '@mui/material/styles';

export const iceFlowTheme = createTheme({
  palette: {
    primary: {
      light: '#4F83E3',
      main: '#1C50B0',
      dark: '#0E2858',
      contrastText: '#F5F3F5',
    },
    secondary: {
      light: '#E0B9F3',
      main: '#DE62E4',
      dark: '#B821C0',
      contrastText: '#F5F3F5',
    },
    error: {
      light: '#FF4747',
      main: '#E00000',
      dark: '#7A0000',
      contrastText: '#F5F3F5',
    },
    warning: {
      light: '#FF4747',
      main: '#E00000',
      dark: '#7A0000',
      contrastText: '#F5F3F5',
    },
    info: {
        light: '#FFCE5C',
        main: '#F5AB00',
        dark: '#8F6400',
        contrastText: '#F5F3F5',
    },
    success: {
        light: '#82B0D9',
        main: '#3A7FBB',
        dark: '#26547C',
        contrastText: '#F5F3F5',  
    },
    neutral: {
      light: '#FFFFFF',
      main: '#A8B6C7',
      dark: '#0C090D',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#1C50B0',
    },
    jump: {
      light: '#FF4747',
      main: '#E00000',
      dark: '#7A0000',
      contrastText: '#F5F3F5',
    },
    spin: {
      light: '#F8D4B4',
      main: '#EE9344',
      dark: '#BB6011',
      contrastText: '#F5F3F5',
    },
    step: {
      light: '#82B0D9',
      main: '#3A7FBB',
      dark: '#26547C',
      contrastText: '#F5F3F5', 
    },
    other: {
      light: '#C3A9DB',
      main: '#9261BD',
      dark: '#5D3681',
      contrastText: '#F5F3F5', 
    },
    audio: {
      light: '#A3D19F',
      main: '#61AF5A',
      dark: '#3A6E35',
      contrastText: '#F5F3F5', 
    }
  },
});
