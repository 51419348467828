import React from 'react';
import { FileControls } from './FileControls';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Header = () => {
    return (
        <AppBar position="fixed" id="app-header">
            <Box sx={{width: '227px', textAlign: 'left'}}><img src="/assets/logo.svg" height="50" /></Box>
            <Box><Typography variant="h3" sx={{fontFamily: 'Whisper, cursive'}}>Ice Flow Choreographer</Typography></Box>
            <FileControls />
        </AppBar>
    )
}