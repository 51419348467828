import React, { useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import WarningIcon from '@mui/icons-material/Warning';

import { JumpPicker } from './JumpPicker';
import { SpinPicker } from './SpinPicker';
import { StepPicker } from './StepPicker';
import { OtherPicker } from './OtherPicker';

import { useTelemetryContext } from '../../context/TelemetryContext';
import { convertSecondsToTimeString } from '../../lib/DataHelpers';
import { programPresets } from '../../lib/ProgramPresets';

export function Element({index, seconds}) {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const el = telemetryState.programElements[index];

    const [showTimeMenu, setShowTimeMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [elementOpen, setElementOpen] = useState(el.note === '' ? false : true);
    const [showDialog, setShowDialog] = useState(false);
    const [ validationError, setValidationError ] = useState('');

    useEffect(() => {
        const errors = [];
        if (index > 0) {
            const prev = telemetryState.programElements[index - 1];
            //Check timing
            if (el.time === prev.time || (el.time > prev.time && el.time <= prev.time + countDuration(prev))) {
                errors.push('Element time overlaps with previous element.');
            }

            //Check element type count
            const preset = programPresets.get(telemetryState.metadata.programType).get(telemetryState.metadata.programLevel) ?? null;
            
            if (preset && telemetryState.metadata.programType !== 'Custom') {
                const jumps = telemetryState.programElements.filter(el => el.type === 'Jump');
                const spins = telemetryState.programElements.filter(el => el.type === 'Spin');
                const steps = telemetryState.programElements.filter(el => el.type === 'Step');

                const scorableElements = telemetryState.programElements.filter(el => el.type !== 'Other');
                const totalScorable = preset['Element Max'];

                if (scorableElements.indexOf(el) >= totalScorable) {
                    errors.push('Max scorable elements exceeded.');
                }

                switch (el.type) {
                    case 'Jump':
                            if (preset['Jump']['max'] > 0 && jumps.indexOf(el) >= preset['Jump']['max']) {
                                errors.push('Max number of jumps exceeded.');
                            }
                        break;
                    case 'Spin':
                        if (preset['Step']['max'] > 0 && spins.indexOf(el) >= preset['Spin']['max']) {
                            errors.push('Max number of spins exceeded.');
                        }
                        break;
                    case 'Step':
                        if (preset['Step']['max'] > 0 && steps.indexOf(el) >= preset['Step']['max']) {
                            errors.push('Max number of step sequences exceeded.');
                        }
                        break;
                    default:
                }
            }
        }

        setValidationError(errors.join(' '));

    }, [telemetryState.programElements[index], telemetryState.programElements[index - 1], countDuration, setValidationError])

    const handleShowTimeMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setShowTimeMenu(true);
    }

    const handleElementOpen = (event) => {
        setElementOpen(!elementOpen);
    }

    const handleDeleteClick = () => {
        setShowDialog(true);
    }

    const handleDeleteEvent = () => {
        const myElements = [...telemetryState.programElements];
        myElements.splice(index, 1);
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
        handleDialogClose();
    }

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const handleNoteChange = (event) => {
        const myElements = [...telemetryState.programElements];
        myElements[index].note = event.target.value;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    function countDuration(element) {
        let duration = 0;
        element.elements.forEach(e => {
            duration += e.time;
        });

        return duration;
    }

    const formatDuration = (duration) => {
        return duration > 0 ? `+${duration}sec` : duration;
    }

    let themeColor = 'primary';
    switch (el.type) {
        case 'Jump':
            themeColor = 'jump';
            break;
        case 'Spin':
            themeColor = 'spin';
            break;
        case 'Step':
            themeColor = 'step';
            break;
        case 'Other':
            themeColor =  'other';
            break;
        default:
            themeColor = 'primary';
    }

    return (
        <Card key={`element-${index}`} className={`element-card${elementOpen ? ' open': ''}`}>
            <CardContent sx={{display: 'flex', flexDirection: 'row', padding: '0', alignItems: 'flex-start', justifyContent: 'space-between'}}>

                <Box className={`element-warning ${validationError !== '' ? 'error' : ''}`}>
                    <Tooltip placement="top" title={validationError} arrow>
                        <WarningIcon size="small" color="info" />
                    </Tooltip>
                </Box>

                <Badge
                    badgeContent={formatDuration(countDuration(el))}
                    color="white"
                    className="element-badge"
                >
                    <Button onClick={handleShowTimeMenu} color={themeColor} variant='contained'>{convertSecondsToTimeString(el.time)}</Button>
                </Badge>
                {el.type === 'Jump' && <JumpPicker index={index} />}
                {el.type === 'Spin' && <SpinPicker index={index} />}
                {el.type === 'Step' && <StepPicker index={index} />}
                {el.type === 'Other' && <OtherPicker index={index} />}
                <Stack direction="row" spacing={1} sx={{padding: '3px 15px 0 0', width: '75px', justifyContent: 'flex-end'}}>
                    <DeleteForeverIcon color={themeColor} onClick={handleDeleteClick} />
                    <ExpandCircleDownIcon color={themeColor} className={`element-expand${elementOpen ? ' open' : ''}`} onClick={handleElementOpen} />
                </Stack>
            </CardContent>
            <Box sx={{padding: '5px'}} className="element-card-input">
                <TextField size="small" fullWidth value={telemetryState.programElements[index].note} onChange={handleNoteChange} />
            </Box>
            
            <TimePopover anchorEl={anchorEl} open={showTimeMenu} setOpen={setShowTimeMenu} index={index} seconds={seconds} />
            
            <Dialog open={showDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete program element?</DialogTitle>
                <DialogContent>
                    <Typography> This action can not be undone.</Typography>
                    <Typography>Are you sure you want to delete the element?</Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleDialogClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteEvent}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

function TimePopover({anchorEl, open, setOpen, seconds, index}) {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const el = telemetryState.programElements[index];

    const marks = [
        {
            value: 0,
            label: '0:00'
        },
        {
            value: seconds,
            label: convertSecondsToTimeString(seconds)
        }
    ];

    const handleSliderChange = (event, value) => {
        const myElements = [...telemetryState.programElements];
        myElements[index].time = value;

        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    const handleClose = () => {
        setOpen(false);
        const myElements = [...telemetryState.programElements];
        myElements.sort((a, b) => a.time - b.time);

        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    return (
        <Popover
            id="time-index-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            sx={{padding: '13px 25px 10px'}}
        >
            <Box sx={{padding: '35px 25px 5px'}}>
            <Slider
                min={0}
                max={seconds}
                getAriaValueText={convertSecondsToTimeString}
                sx={{width: '400px'}}
                size='small'
                valueLabelDisplay="on"
                valueLabelFormat={convertSecondsToTimeString}
                value={el.time}
                marks={marks}
                onChange={handleSliderChange}
            />
            </Box>
        </Popover>
    )
}