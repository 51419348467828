import { Color, Layer, Path } from 'paper';

export const Draw = (project) => {
    const draw = new Layer({name: "Draw"});

    const drawPath = new Path({
        name: 'Draw',
        strokeColor: 'red',
        strokeWidth: 2
    });

    const interactionPath = new Path.Rectangle({
        name: 'interactionPath',
        topLeft: [0, 0],
        bottomRight: [project.rinkWidth, project.rinkHeight],
        radius: project.rinkRadius,
        fillColor: new Color('rgba(255, 255, 255, 0.01)'),
        visible: true
    });

    draw.addChild(interactionPath);
    draw.addChild(drawPath);

    project.addLayer(draw);
}