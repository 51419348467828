import { Color, Path, Point, PointText } from 'paper';
import { convertSecondsToTimeString } from '../../lib/DataHelpers';

const colorMap = {
    'Jump': {main: '#E00000', dark: '#7A0000'},
    'Spin': {main: '#EE9344', dark: '#BB6011'},
    'Step': {main: '#3A7FBB', dark: '#26547C'},
    'Other': {main: '#9261BD', dark: '#5D3681'},
    'Song': {main: '#61AF5A', dark: '#3A6E35'}
}

export const addElements = (project) => {
    const programPath = project.layers['Program'].children['Program'];
    if (programPath.segments.length === 0) return;
    
    addProgramElements(project);
    addAudioElements(project);
}

function addProgramElements(project) {
    const programPath = project.layers['Program'].children['Program'];
    const canvasCenter = new Point(project.rinkWidth/2, project.rinkHeight/2);

    const elements = project.layers["Program Elements Layer"].children['Elements'];
    const scaleMultiplier = project.globalScale * project.telemetryState.metadata.programSpeed * 1.466667;

    elements.removeChildren();

    project.timingElements.filter(el => el.type !== 'Song').forEach(el => {
        let token = null;
        let textStart = null;
        
        if (el.type === 'Spin' || el.offset === 0) {
            textStart = programPath.getPointAt(el.adjustedTime * scaleMultiplier);
            token = new Path.Circle({
                center: textStart,
                radius: 10,
                fillColor: colorMap[el.type].main,
                strokeColor: colorMap[el.type].main,
                strokeWidth: 2
            });
        } else {
            let workingPath = programPath.clone({insert: false, deep: false});
            textStart = workingPath.getPointAt(el.adjustedTime * scaleMultiplier);

            const split1 = workingPath.splitAt(el.adjustedTime * scaleMultiplier);
            split1.splitAt(el.offset * scaleMultiplier);

            token = split1;
            token.strokeColor = colorMap[el.type].main;
            token.strokeWidth = 20;
            token.strokeCap = 'round';
        }
        elements.addChild(token);

        if (textStart) {
            const label = new PointText({
                point: textStart,
                content: `${convertSecondsToTimeString(el.timeIndex)} ${el.code}`,
                fillColor: 'black',
                fontFamily: 'Arial',
                fontSize: 12,
                justification: 'center',
                shadowColor: new Color(1, 1, 1),
                shadowBlur: 5,
                shadowOffset: new Point(0, 0)
            });

            let vectorToCenter = canvasCenter.subtract(textStart);
            let normalizedVector = vectorToCenter.normalize();
            let labeledVector = new Point(
                normalizedVector.x * (label.bounds.width/2 + 15),
                normalizedVector.y * (label.bounds.height + 15)
            );

            label.position = textStart.add(labeledVector);

            elements.addChild(label);
        }
    });
}

function addAudioElements(project) {
    const programPath = project.layers['Program'].children['Program'];
    const elements = project.layers["Audio Elements Layer"].children['Elements'];
    const canvasCenter = new Point(project.rinkWidth/2, project.rinkHeight/2);

    const scaleMultiplier = project.globalScale * project.telemetryState.metadata.programSpeed * 1.466667;

    elements.removeChildren();

    project.timingElements.filter(el => el.type === 'Song').forEach(el => {
        let textStart = programPath.getPointAt(el.adjustedTime * scaleMultiplier);
        const token = new Path.RegularPolygon({
            center: programPath.getPointAt(el.adjustedTime * scaleMultiplier),
            sides: 3,
            radius: 10,
            fillColor: colorMap['Song'].main,
            strokeColor: colorMap['Song'].dark,
            strokeWidth: 2
        });
        elements.addChild(token);

        if (textStart) {
            const label = new PointText({
                point: textStart,
                content: `${convertSecondsToTimeString(el.timeIndex)}`,
                fillColor: 'black',
                fontFamily: 'Arial',
                fontSize: 12,
                justification: 'center',
                shadowColor: new Color(1, 1, 1),
                shadowBlur: 5,
                shadowOffset: new Point(0, 0)
            });

            let vectorToCenter = canvasCenter.subtract(textStart);
            let normalizedVector = vectorToCenter.normalize();
            let labeledVector = new Point(
                normalizedVector.x * (label.bounds.width/2 + 13),
                normalizedVector.y * (label.bounds.height/2 + 13)
            );

            label.position = textStart.add(labeledVector);

            elements.addChild(label);
        }
    });
}