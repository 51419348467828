import { Color, Group, Layer, Path } from 'paper';
import RinkDimensions from '../../lib/RinkDimensions';

export const GridLines = (project) => {
    const grid = new Layer({name: "Grid"});
    const rinkGrid = new Group({name: 'RinkGrid'});
    grid.addChild(rinkGrid);
    let rinkSize = project.telemetryState.metadata?.rinkSize ?? 'default';

    const gridColor = new Color('rgba(57, 255, 20, .25)');  //#39FF14

    rinkGrid.onFrame = () => {
        const currentRinkSize = project.telemetryState.metadata?.rinkSize;
        if (currentRinkSize && currentRinkSize !== rinkSize) {
            rinkSize = currentRinkSize;
            redrawGridLines();
        }
    }

    const redrawGridLines = () => {
        rinkGrid.removeChildren();

        //Build vertical grid lines
        const spacing = 5 * project.globalScale;
        const rinkWidth = RinkDimensions.get(rinkSize).width * project.globalScale;
        const rinkHeight = RinkDimensions.get(rinkSize).height * project.globalScale;
        let offsetX = 0;
        let offsetY = 0;

        while ((offsetX * spacing) + (rinkWidth / 2) < rinkWidth) {
            const offset = offsetX * spacing;
            const vPathPos = new Path.Line({
                from: [rinkWidth / 2 + offset , 0],
                to: [rinkWidth /2 + offset, rinkHeight],
                strokeColor: gridColor,
                strokeWidth: 1,
            });
            rinkGrid.addChild(vPathPos);

            const vPathNeg = new Path.Line({
                from: [rinkWidth / 2 - offset , 0],
                to: [rinkWidth /2 - offset, rinkHeight],
                strokeColor: gridColor,
                strokeWidth: 1,
            });
            rinkGrid.addChild(vPathNeg);
            offsetX++;
        }

        while ((offsetY * spacing) + (rinkHeight / 2) < rinkHeight) {
            const offset = offsetY * spacing;
            const hPathPos = new Path.Line({
                from: [0, rinkHeight / 2 + offset],
                to: [rinkWidth, rinkHeight / 2 + offset],
                strokeColor: gridColor,
                strokeWidth: 1,
            });
            rinkGrid.addChild(hPathPos);

            const hPathNeg = new Path.Line({
                from: [0, rinkHeight / 2 - offset],
                to: [rinkWidth, rinkHeight / 2 - offset],
                strokeColor: gridColor,
                strokeWidth: 1,
            });
            rinkGrid.addChild(hPathNeg);
            offsetY++;
        }
    }
    redrawGridLines();

    project.addLayer(grid);
}