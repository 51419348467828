import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AudioMetadata } from './AudioMetadata';
import { AudioControls } from './AudioControls';
import { AudioAnnotations } from './AudioAnnotations';

export const AudioPanel = () => {
    return (
        <Stack className="program-control-audio">
            <Typography variant='h6' align='left' gutterBottom={true}>Program Audio</Typography>
            <AudioMetadata />
            <AudioControls />
            <AudioAnnotations />
        </Stack>
    )
}