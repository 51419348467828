import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import PaperCanvas from './components/CanvasPanel/PaperCanvas';
import { ThemeProvider } from '@mui/material/styles';
import { CanvasTools } from './components/CanvasPanel/CanvasTools';
import { AppContextProvider } from './context/AppContext';
import { TelemetryContextProvider } from './context/TelemetryContext';
import { AudioPanel } from './components/AudioPanel/AudioPanel';
import { ProgramElements } from './components/ProgramPanel/ProgramElements';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import Stack from '@mui/material/Stack';

import { iceFlowTheme } from './theme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles/App.scss';

function App() {
  const [dialogOpen, setDialogOpen] = useState(true);

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  return (
    <ThemeProvider theme={iceFlowTheme}>
      <AppContextProvider>
        <div className="App">         
          <TelemetryContextProvider>
            <Header />
            <CanvasTools />
            <PaperCanvas />
            <Stack direction="row" spacing={0} className="program-control-wrapper">
              <ProgramElements />
              <AudioPanel />
            </Stack>
            <Footer />
          </TelemetryContextProvider>
          <Dialog
            fullScreen
            open={dialogOpen}
            onClick={handleDialogClose}
            className="loading-screen"
            PaperProps={{
              sx: {backgroundColor: 'transparent', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'}
            }}
          >
            <img src="/assets/logo.svg" height="350" />
            <Typography variant="h1" sx={{fontFamily: 'Whisper, cursive', marginTop: '25px'}}>Ice Flow Choreographer</Typography>
            <Typography variant="h6" sx={{color: '#F5F3F573'}}>Click to begin</Typography>
          </Dialog>
        </div>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
