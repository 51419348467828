import React, { useEffect, useRef, useState } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';

import WaveSurfer from 'wavesurfer.js';

import { useTelemetryContext } from '../../context/TelemetryContext';
import { useAppContext } from '../../context/AppContext';

export const AudioControls = () => {
    const { state: telemetryState, dispatch: telemetryDispatch } = useTelemetryContext();
    const { dispatch: appDispatch } = useAppContext();

    const wavesurferRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const createWavesurfer = () => {
            wavesurferRef.current = WaveSurfer.create({
                container: '#waveform',
                height: 64,
                waveColor: '#576CA8',
                progressColor: '#1B264F',
                cursorColor: '#1B264F',
                cursorWidth: 4,
                barWidth: 2,
                barGap: 2,
                mediaControls: true,
                dragToSeek: true,
            });
    
            appDispatch({type: 'SET_WAVESURFER', value: wavesurferRef.current});
    
            wavesurferRef.current.on('ready', () => {
                if (wavesurferRef.current.getMediaElement().getAttribute('src')) {
                    setIsLoaded(true);
                    const duration = Math.floor(wavesurferRef.current.getDuration());
                    telemetryDispatch({type: 'UPDATE_METADATA', value: { songLength: duration}});
                }
            });
    
            wavesurferRef.current.on('destroy', () => {
                setIsLoaded(false);
                // telemetryDispatch({type: 'UPDATE_METADATA', value: { songLength: 0}});
                // telemetryDispatch({type: 'UPDATE_DECODED_AUDIO', value: null});
                appDispatch({type: 'SET_WAVESURFER', value: null});
            });
    
            wavesurferRef.current.on('error', (error) => {
                console.log('wavesurfer error: ', error);
            })
        }

        createWavesurfer();

        return () => {
            if (wavesurferRef.current) {
              wavesurferRef.current.destroy();
            }
        };
    }, [appDispatch, telemetryDispatch]);

    useEffect(() => {
        if (telemetryState.decodedAudio !== null) {
            try {
                wavesurferRef.current.load(telemetryState.decodedAudio);
            } catch (error) {
                console.log(error);
            }
            setIsLoaded(true);
        } else {
            wavesurferRef.current.empty();
            setIsLoaded(false);
        }
    }, [telemetryState.decodedAudio]);



    // const handleFileSelect = (event) => {
    //     const file = event.target.files[0];
    //     handleFileUpload(file);
    // };

    const handleFileUpload = (selectedFile) => {
        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = function(event) {
                loadAudio(event.target.result);
            };

            reader.onerror = function(event) {
                console.log(event);
            }
            
            reader.readAsDataURL(selectedFile);
        }
    }

    const loadAudio = (audioBlob) => {
        if (audioBlob && wavesurferRef.current) {
            telemetryDispatch({ type: 'UPDATE_DECODED_AUDIO', value: audioBlob })
        }
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    
        const files = Array.from(e.dataTransfer.files);
        handleFileUpload(files[0]);
      };

    return (
        <>
            <div id="waveform" className={isLoaded ? '' : 'hidden'}></div>
            {!isLoaded &&
            <ButtonGroup color="primary" sx={{justifyContent: 'center'}}>
                {/*<TextField hiddenLabel variant="standard" type="file" sx={{marginRight: '15px'}} onChange={handleFileSelect} />*/}
                <Button
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    fullWidth
                    variant={isDragging ? "contained" : "outlined"}
                    size="large"
                    sx={{height: '100px', borderStyle: 'dashed'}}
                >
                    Drop audio file to import
                </Button>
            </ButtonGroup>
            }
        </>
    )
}