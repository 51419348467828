const singleFree = new Map([
   ['Senior', {
        'Element Max': 12,
        'Time': {'max': 240, 'delta': 10 },
        'Jump': {'max': 7, 'allowed': []},
        'Spin': {'max': 3, 'allowed': []},
        'Step': {'max': 2, 'allowed': []}
    }],
    ['Junior', {
        'Element Max': 11,
        'Time': {'max': 210, 'delta': 10 },
        'Jump': {'max': 7, 'allowed': []},
        'Spin': {'max': 3, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Novice', {
        'Element Max': 11,
        'Time': {'max': 210, 'delta': 10 },
        'Jump': {'max': 7, 'allowed': []},
        'Spin': {'max': 3, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Intermediate', {
        'Element Max': 9,
        'Time': {'max': 180, 'delta': 10 },
        'Jump': {'max': 6, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Juvenile', {
        'Element Max': 8,
        'Time': {'max': 150, 'delta': 10 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Open Juvenile', {
        'Element Max': 8,
        'Time': {'max': 150, 'delta': 10 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Pre-Juvenile', {
        'Element Max': 8,
        'Time': {'max': 120, 'delta': 10 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Preliminary', {
        'Element Max': 8,
        'Time': {'max': 120, 'delta': 10 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Pre-Preliminary', {
        'Element Max': 8,
        'Time': {'max': 100, 'delta': 0 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['No Test', {
        'Element Max': 8,
        'Time': {'max': 100, 'delta': 10 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }]
]);

const singleShort = new Map([
    ['Senior', {
        'Element Max': 7,
        'Time': {'max': 160, 'delta': 10 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }],
    ['Junior', {
        'Element Max': 7,
        'Time': {'max': 160, 'delta': 10 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }],
    ['Novice', {
        'Element Max': 6,
        'Time': {'max': 150, 'delta': 0 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }],
    ['Intermediate', {
        'Element Max': 6,
        'Time': {'max': 130, 'delta': 0 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }],
    ['Juvenile', {
        'Element Max': 6,
        'Time': {'max': 130, 'delta': 0 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }],
    ['Open Juvenile', {
        'Element Max': 6,
        'Time': {'max': 130, 'delta': 0 },
        'Jump': {'max': null, 'allowed': []},
        'Spin': {'max': null, 'allowed': []},
        'Step': {'max': null, 'allowed': []}
    }]
]);

const adultSingleFree = new Map([
    ['Gold', {
        'Element Max': 9,
        'Time': {'max': 160, 'delta': 0 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 3, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Silver', {
        'Element Max': 8,
        'Time': {'max': 130, 'delta': 0 },
        'Jump': {'max': 5, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Bronze', {
        'Element Max': 7,
        'Time': {'max': 110, 'delta': 0 },
        'Jump': {'max': 4, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 1, 'allowed': []}
    }],
    ['Pre-Bronze', {
        'Element Max': 6,
        'Time': {'max': 100, 'delta': 0 },
        'Jump': {'max': 4, 'allowed': []},
        'Spin': {'max': 2, 'allowed': []},
        'Step': {'max': 0, 'allowed': []}
    }]
]);

const customFree = new Map([
    ['Custom', {
        'Element Max': 0,
        'Time': {'max': 600, 'delta': 0},
        'Jump': {'max': 0, 'allowed': []},
        'Spin': {'max': 0, 'allowed': []},
        'Step': {'max': 0, 'allowed': []},
    }]
]);

export const programPresets = new Map([
    ['Singles Free', singleFree],
    ['Singles Short', singleShort],
    ['Adult Singles Free', adultSingleFree],
    ['Custom', customFree]
]);