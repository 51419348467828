import { Group, Layer } from 'paper';

export const ProgramElements = (project) => {
    const layer = new Layer({
        name: "Program Elements Layer",
        visible: false
    });
    const elements = new Group({name: 'Elements'});
    layer.addChild(elements);

    layer.setVisibility = (state) => {
        layer.visible = state;
    }

    project.addLayer(layer);
}