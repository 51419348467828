import { Color, Group, Layer, Path, Point, Shape } from 'paper';
import RinkDimensions from '../../lib/RinkDimensions';

export const HockeyLines = (project) => {
    const hockeyLines = new Layer({name: "HockeyLines"});
    const rinkHockeyLines = new Group({name: 'RinkHockeyLines'});
    hockeyLines.addChild(rinkHockeyLines);

    const nhlRed = new Color('rgba(200, 16, 46, .25)');
    const nhlBlue = new Color('rgba(0, 51, 160, .25)');

    const faceOffCircle = new Shape.Circle({
        radius: 15 * project.globalScale,
        strokeColor: nhlRed,
        strokeWidth: .17 * project.globalScale
    });
    const faceOffCircleTopRight = faceOffCircle.clone();
    const faceOffCircleBottomLeft = faceOffCircle.clone();
    const faceOffCircleBottomRight = faceOffCircle.clone();
    faceOffCircle.name = 'faceOffCircleTopLeft';
    faceOffCircleTopRight.name = 'faceOffCircleTopRight';
    faceOffCircleBottomLeft.name = 'faceOffCircleBottomLeft';
    faceOffCircleBottomRight.name = 'faceOffCircleBottomRight';

    const faceOffSpot = new Shape.Circle({
        radius: 1 * project.globalScale,
        fillColor: nhlRed,
    });
    const faceOffSpotTopRight = faceOffSpot.clone();
    const faceOffSpotBottomLeft = faceOffSpot.clone();
    const faceOffSpotBottomRight = faceOffSpot.clone();
    const faceOffSpotNeutralTopLeft = faceOffSpot.clone();
    const faceOffSpotNeutralTopRight = faceOffSpot.clone();
    const faceOffSpotNeutralBottomLeft = faceOffSpot.clone();
    const faceOffSpotNeutralBottomRight = faceOffSpot.clone();
    faceOffSpot.name = 'faceOffSpotTopLeft';
    faceOffSpotTopRight.name = 'faceOffSpotTopRight';
    faceOffSpotBottomLeft.name = 'faceOffSpotBottomLeft';
    faceOffSpotBottomRight.name = 'faceOffSpotBottomRight';
    faceOffSpotNeutralTopLeft.name = 'faceOffSpotNeutralTopLeft';
    faceOffSpotNeutralTopRight.name = 'faceOffSpotNeutralTopRight';
    faceOffSpotNeutralBottomLeft.name = 'faceOffSpotNeutralBottomLeft';
    faceOffSpotNeutralBottomRight.name = 'faceOffSpotNeutralBottomRight';

    const goalLine = new Path.Line({
        strokeColor: nhlRed,
        strokeWidth: .17 * project.globalScale
    });
    const goalLineRight = goalLine.clone();
    goalLine.name = 'goalLineLeft';
    goalLineRight.name = 'goalLineRight';

    const blueLine = new Path.Line({
        strokeColor: nhlBlue,
        strokeWidth: 1 * project.globalScale
    });
    const blueLineRight = blueLine.clone();
    blueLine.name = 'blueLineLeft';
    blueLineRight.name = 'blueLineRight';

    const centerCircle = new Shape.Circle({
        name: 'centerCircle',
        radius: 15 * project.globalScale,
        strokeColor: nhlBlue,
        strokeWidth: .17 * project.globalScale
    });

    const centerSpot = new Shape.Circle({
        name: 'centerSpot',
        radius: .5 * project.globalScale,
        fillColor: nhlBlue
    });

    const centerLine = new Path.Line({
        name: 'centerLine',
        strokeColor: nhlRed,
        strokeWidth: 1 * project.globalScale
    });

    rinkHockeyLines.addChildren([
        centerSpot,
        centerLine,
        centerCircle,
        faceOffCircle,
        faceOffCircleTopRight,
        faceOffCircleBottomLeft,
        faceOffCircleBottomRight,
        faceOffSpot,
        faceOffSpotTopRight,
        faceOffSpotBottomLeft,
        faceOffSpotBottomRight,
        faceOffSpotNeutralTopLeft,
        faceOffSpotNeutralTopRight,
        faceOffSpotNeutralBottomLeft,
        faceOffSpotNeutralBottomRight,
        goalLine,
        goalLineRight,
        blueLine,
        blueLineRight,
    ]);

    rinkHockeyLines.onFrame = (event) => {
        const rinkWidth = RinkDimensions.get(project.telemetryState?.metadata?.rinkSize ?? 'default').width * project.globalScale;
        const rinkHeight = RinkDimensions.get(project.telemetryState?.metadata?.rinkSize ?? 'default').height * project.globalScale;
        const centerPoint = new Point(rinkWidth/2, rinkHeight/2);

        //Center
        rinkHockeyLines.children['centerSpot'].position = centerPoint;
        rinkHockeyLines.children['centerCircle'].position = centerPoint;
        rinkHockeyLines.children['centerLine'].segments[0].point = new Point(centerPoint.x, 0);
        rinkHockeyLines.children['centerLine'].segments[1].point = new Point(centerPoint.x, rinkHeight);

        //Left
        rinkHockeyLines.children['goalLineLeft'].segments[0].point = new Point(11 * project.globalScale, 0);
        rinkHockeyLines.children['goalLineLeft'].segments[1].point = new Point(11 * project.globalScale, rinkHeight);

        rinkHockeyLines.children['blueLineLeft'].segments[0].point = new Point(75 * project.globalScale, 0);
        rinkHockeyLines.children['blueLineLeft'].segments[1].point = new Point(75 * project.globalScale, rinkHeight);

        rinkHockeyLines.children['faceOffSpotTopLeft'].position = new Point(31 * project.globalScale, centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotBottomLeft'].position = new Point(31 * project.globalScale, centerPoint.y + (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotNeutralTopLeft'].position = new Point(80 * project.globalScale, centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotNeutralBottomLeft'].position = new Point(80 * project.globalScale, centerPoint.y + (22 * project.globalScale));

        rinkHockeyLines.children['faceOffCircleTopLeft'].position = new Point(31 * project.globalScale, centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffCircleBottomLeft'].position = new Point(31 * project.globalScale, centerPoint.y + (22 * project.globalScale));

        //Right
        rinkHockeyLines.children['goalLineRight'].segments[0].point = new Point(rinkWidth - (11 * project.globalScale), 0);
        rinkHockeyLines.children['goalLineRight'].segments[1].point = new Point(rinkWidth - (11 * project.globalScale), rinkHeight);

        rinkHockeyLines.children['blueLineRight'].segments[0].point = new Point(rinkWidth - (75 * project.globalScale), 0);
        rinkHockeyLines.children['blueLineRight'].segments[1].point = new Point(rinkWidth - (75 * project.globalScale), rinkHeight);

        rinkHockeyLines.children['faceOffSpotTopRight'].position = new Point(rinkWidth - (31 * project.globalScale), centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotBottomRight'].position = new Point(rinkWidth - (31 * project.globalScale), centerPoint.y + (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotNeutralTopRight'].position = new Point(rinkWidth - (80 * project.globalScale), centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffSpotNeutralBottomRight'].position = new Point(rinkWidth - (80 * project.globalScale), centerPoint.y + (22 * project.globalScale));

        rinkHockeyLines.children['faceOffCircleTopRight'].position = new Point(rinkWidth - (31 * project.globalScale), centerPoint.y - (22 * project.globalScale));
        rinkHockeyLines.children['faceOffCircleBottomRight'].position = new Point(rinkWidth - (31 * project.globalScale), centerPoint.y + (22 * project.globalScale));
    }

    project.addLayer(hockeyLines);
}