import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import { CanvasDrawMode } from './CanvasDrawMode';
import { CanvasViewMode } from './CanvasViewMode';
import { CanvasTelemetry } from './CanvasTelemetry';

import { useAppContext } from '../../context/AppContext';

export const CanvasTools = () => {
    const { state: appState, dispatch: appDispatch } = useAppContext();

    const toggleExpand = () => {
        if (appState.canvasVisible !== false) {
            appDispatch({type: 'SET_CANVAS_VISIBILITY', value: false})
        } else {
            appDispatch({type: 'SET_CANVAS_VISIBILITY', value: true})
        }
        
    }

    return (
        <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{padding: '10px 0', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '0 auto'}}
        >
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: '10px'}}>
                <Typography variant='h6' align='left' gutterBottom={true}>Program&nbsp;Canvas</Typography>
                <ExpandCircleDownIcon variant="outlined" color="primary" onClick={toggleExpand} className={`canvas-expand ${appState.canvasVisible ? 'open' : ''}`} />
            </Box>
            {appState.canvasVisible !== false && <CanvasDrawMode />}
            {appState.canvasVisible !== false && <CanvasViewMode />}
            <CanvasTelemetry />
            
        </Stack>
    )
}