import React, { createContext, useContext, useReducer } from 'react';

import { useAppContext } from './AppContext';

const initialState = {
    metadata: {
        programName: '',
        programType: '',
        programLevel: '',
        programLength: 0,
        programSpeed: 8,
        songLength: 0,
        songTitle: '',
        songArtist: '',
        rinkSize: 'default'
    },
    programPath: null,
    songAnnotations: {},
    programElements: [],
    decodedAudio: null,
    dirty: false
}

const TelemetryContext = createContext();
var appState = undefined;
var appDispatcher = undefined;

export const TelemetryContextProvider = ({ children }) => {
    const {state: appContext, dispatch: appDispatch } = useAppContext();
    const [state, dispatch] = useReducer(reducer, initialState);
    appState = appContext;
    appDispatcher = appDispatch;

    return (
        <TelemetryContext.Provider value={{state, dispatch}}>
            {children}
        </TelemetryContext.Provider>
    );
};

export const useTelemetryContext = () => useContext(TelemetryContext);

const reducer = (state, action) => {
    let newState = {};
    switch (action.type) {
        case 'UPDATE_ANNOTATIONS':
            newState = { ...state, songAnnotations: action.value, dirty: true };
            break;
        case 'UPDATE_PROGRAM_ELEMENTS':
            newState = { ...state, programElements: action.value, dirty: true };
            break;
        case 'UPDATE_METADATA':
            newState = { ...state, metadata: { ...state.metadata, ...action.value }, dirty: true};
            break;
        case 'UPDATE_DECODED_AUDIO':
            newState = { ...state, decodedAudio: action.value, dirty: true };
            break;
        case 'UPDATE_PROGRAM_PATH':
            newState = { ...state, programPath: action.value, dirty: true };
            break;
        case 'SET_DIRTY':
            newState = { ...state, dirty: action.value };
            break;
        case 'IMPORT_DATA':
            newState = { ...action.value, dirty: false};
            break;
        default:
            newState = state;
    }

    if (appState.paper && action.type !== 'UPDATE_DECODED_AUDIO') {
        appState.paper.updateProjectData(newState);
    }

    return newState;
};

export default TelemetryContext;