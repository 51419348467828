import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import { useAppContext } from '../../context/AppContext';

import GridOnIcon from '@mui/icons-material/GridOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PlaceIcon from '@mui/icons-material/Place';

export const CanvasViewMode = () => {
    const { state: appState, dispatch: appDispatch } = useAppContext();

    const changeViewMode = ( event, mode) => {
        appDispatch({type: 'SET_VIEW_MODE', value: mode});
    }

    return (
        <ToggleButtonGroup onChange={changeViewMode} color="primary" value={appState.viewMode}>
            <Tooltip title="Toggle Grid"><ToggleButton value="grid"><GridOnIcon /></ToggleButton></Tooltip>
            <Tooltip title="Toggle Hockey Lines"><ToggleButton value="lines"><LocationSearchingIcon /></ToggleButton></Tooltip>
            <Tooltip title="Toggle Program Elements"><ToggleButton value="elements"><PlaceIcon /></ToggleButton></Tooltip>
            <Tooltip title="Toggle Audio Elements"><ToggleButton value="audio"><MusicNoteIcon /></ToggleButton></Tooltip>
        </ToggleButtonGroup>
    )
}