export const exportFile = (state) => {
    // Serialize combinedData to a JSON string
    const jsonString = JSON.stringify(state);

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger download
    const link = document.createElement('a');
    link.href = url;

    link.download = formatFileName(state.metadata.programName);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
};

const formatFileName = (name) => {
    if (name === '') {
        return 'program-data.json';
    } else {
        name = name.toLowerCase();
        name = name.replace(/\s+/g, '-');
        name = name.replace(/[^\w\d-]/g, '');

        return name;
    }
}