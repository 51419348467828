import { Path, Point, Segment } from 'paper';

const onMouseDrag = (event, drawPath) => {
    if (drawPath.lastSegment.point.getDistance(event.point) >= 25) {
        const eventPoint = new Point(event.point);
        drawPath.addSegments([eventPoint]);
    }

    return false;
}

const onMouseDown = (event, drawPath) => {
    const startPoint = new Point(event.point);

    const pathAnchor = new Path.Circle({
        center: startPoint, 
        radius: 5,
        strokeWidth: 2,
        strokeColor: 'black'
    });
    drawPath.addSegments([new Segment(startPoint, null, null), new Segment(startPoint, null, null)]);

    pathAnchor.removeOnUp();
    return false;
}

const onMouseUp = (programPath, drawPath) => {
    drawPath.simplify({tolerance: 0});
    drawPath.smooth({ type: 'catmull-rom', factor: 0.5});
    programPath.join(drawPath.clone());
    try {
        programPath.smooth({type: 'catmull-rom', factor: 0, from: -2, to: -1});
    } catch (error) {

    }
    programPath.project.layers['Program'].updateTokens();
    programPath.project.updateTelemetry({type: 'UPDATE_PROGRAM_PATH', value: programPath.exportJSON({asString: false})});
    programPath.project.updateTelemetry({type: 'UPDATE_METADATA', value: { programLength: programPath.length / programPath.project.globalScale }});

    drawPath.removeSegments();
}

const GestureMouseEvents = (project) => {
    const drawPath = project.layers['Draw'].children['Draw']
    const programPath = project.layers['Program'].children['Program'];

    return {
        mousedown: (event) => { onMouseDown(event, drawPath); },
        mouseup: (event) => { onMouseUp(programPath, drawPath); },
        mousedrag: (event) => { onMouseDrag(event, drawPath); },
    }
}

export default GestureMouseEvents;
