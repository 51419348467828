import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { programPresets } from '../../lib/ProgramPresets';
import { convertSecondsToTimeString } from '../../lib/DataHelpers';
import { useTelemetryContext } from '../../context/TelemetryContext';
import { Element } from './Element';

export const ProgramElements = () => {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const [programDetails, setProgramDetails] = useState('');
    const [typeMenuOpen, setTypeMenuOpen] = useState(false);
    const [levelMenuOpen, setLevelMenuOpen] = useState(false);
    const [typeAnchorEl, setTypeAnchorEl] = useState(null);
    const [levelAnchorEl, setLevelAnchorEl] = useState(null);

    useEffect(() => {
        if (telemetryState.metadata.programType && telemetryState.metadata.programLevel) {
            setProgramDetails(programPresets.get(telemetryState.metadata.programType).get(telemetryState.metadata.programLevel));
        }
    }, [telemetryState.metadata.programType, telemetryState.metadata.programLevel])

    const handleAddElement = (type) => {
        const myElements = [...telemetryState.programElements];
        myElements.push({time: 0, type: type, note: '', elements: []});
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    const handleProgramNameChange = (event) => {
        dispatch({type: 'UPDATE_METADATA', value: { programName: event.target.value }});
    }

    const handleTypeMenuOpen = (event) => {
        setTypeAnchorEl(event.currentTarget);
        setTypeMenuOpen(true);
    }
    const handleTypeMenuClose = () => {setTypeMenuOpen(false);}
    const handleLevelMenuOpen = (event) => {
        setLevelAnchorEl(event.currentTarget);
        setLevelMenuOpen(true);
    }
    const handleLevelMenuClose = () => {setLevelMenuOpen(false);}

    const handleProgramTypeChange = (value) => {
        dispatch({type: 'UPDATE_METADATA', value: { programType: value }});
        dispatch({type: 'UPDATE_METADATA', value: { programLevel: '' }});
        handleTypeMenuClose();
    }

    const handleProgramLevelChange = (value) => {
        dispatch({type: 'UPDATE_METADATA', value: { programLevel: value }});
        handleLevelMenuClose();
    }

    return (
        <Stack className="program-control-elements" sx={{paddingRight: '25px'}}>
            <Typography variant='h6' align='left' gutterBottom={true}>Program Elements</Typography>
            <Stack direction="row" spacing={2} sx={{marginBottom: '25px', alignItems: 'flex-end'}}>
                <Button color="secondary" size="small" variant="contained" sx={{textWrap: 'nowrap', width: '155px'}} onClick={handleTypeMenuOpen}>{telemetryState.metadata.programType !== '' ? telemetryState.metadata.programType : 'Program Type'}</Button>
                <Button color="secondary" size="small" variant="contained" sx={{textWrap: 'nowrap', width: '140px'}} onClick={handleLevelMenuOpen} disabled={telemetryState.metadata.programType === ''}>{telemetryState.metadata.programLevel !== '' ? telemetryState.metadata.programLevel : 'Program Level'}</Button>
                <TextField value={telemetryState.metadata.programName} label="Program Name" size="small" variant="standard" onChange={handleProgramNameChange} sx={{alignSelf: 'flex-end', width: '50%'}} />
                <Menu
                    id="program-type-menu"
                    anchorEl={typeAnchorEl}
                    open={typeMenuOpen}
                    onClose={handleTypeMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    sx={{maxHeight: '500px'}}
                >
                    {[...programPresets].map(([key, value], i) => {
                        return (
                            <MenuItem key={`${key}-${value}`} value={key} onClick={() => {handleProgramTypeChange(key)}}>{key}</MenuItem>
                        )
                    })}
                </Menu>
                <Menu
                    id="program-level-menu"
                    anchorEl={levelAnchorEl}
                    open={levelMenuOpen}
                    onClose={handleLevelMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    sx={{maxHeight: '500px'}}
                >
                    {telemetryState.metadata.programType !== '' &&[...programPresets.get(telemetryState.metadata.programType)].map(([key, value], i) => {
                        return (
                            <MenuItem key={`${key}-${value}`} value={key} onClick={() => {handleProgramLevelChange(key)}}>{key}</MenuItem>
                        )
                    })}
                </Menu>
            </Stack>
            {programDetails !== '' && (
                <>
                <Stack>
                    <Typography size='large' sx={{fontWeight: 'bold', textAlign: 'left', marginBottom: '0', borderBottom: '1px #DDD solid'}}>
                        Elements
                    </Typography>
                    <Stack direction="row" className="element-metrics" sx={{fontWeight: 'bold'}}>
                        <Chip label={`Time: ${convertSecondsToTimeString(programDetails['Time'].max)} \u00B1${programDetails['Time'].delta}`} color='other' />
                        <Chip label={`Max Elements: ${programDetails['Element Max']}`} color='other' />
                        <Chip label={`Jumps: ${programDetails['Jump'].max === null ? '—' : programDetails['Jump'].max}`} color='jump' />
                        <Chip label={`Spins: ${programDetails['Spin'].max === null ? "—" : programDetails['Spin'].max}`} color='spin' />
                        <Chip label={`Step Sequences: ${programDetails['Step'].max === null ? "—" : programDetails['Step'].max}`} color='step' />
                    </Stack>

                    {telemetryState.programElements.length > 0 && (
                        telemetryState.programElements.map((el, i) => {
                            return (
                                <Element index={i} seconds={programDetails['Time'].max + programDetails['Time'].delta} key={`element-${i}`} />
                            )
                        })
                    )}
                </Stack>
                <Tooltip title="Add new element">
                    <ButtonGroup fullWidth size="small" color="primary" variant="contained">
                        <Button color="jump" onClick={() => {handleAddElement('Jump')}} value='Jump' endIcon={<AddCircleIcon />}>Jump</Button>
                        <Button color="spin" onClick={() => {handleAddElement('Spin')}} value='Spin' endIcon={<AddCircleIcon />}>Spin</Button>
                        <Button color="step" onClick={() => {handleAddElement('Step')}} value='Step' endIcon={<AddCircleIcon />}>Step</Button>
                        <Button color="other" onClick={() => {handleAddElement('Other')}} value='Other' endIcon={<AddCircleIcon />}>Other</Button>
                    </ButtonGroup>
                </Tooltip>
                </>
            )}
        </Stack>
    )
}
