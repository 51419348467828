import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';

import StarIcon from '@mui/icons-material/Star';
import ReportIcon from '@mui/icons-material/Report';
import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import Guide from './Guide';
import { exportFile } from '../lib/exportHelpers';
import { convertSecondsToTimeString } from '../lib/DataHelpers';
import { useTelemetryContext } from '../context/TelemetryContext';
import { useAppContext } from '../context/AppContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FileControls = () => {
    const { state: telemetryState, dispatch: telemetryDispatch } = useTelemetryContext();

    const [showDialog, setShowDialog] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [elementTable, setElementTable] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [showGuide, setShowGuide] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (telemetryState.dirty) {
                event.preventDefault();
                event.returnValue = ''; // For some browsers
                alert("You have unsaved changes!");
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [telemetryState.dirty]);

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const handleDownloadClick = () => {
        exportFile(telemetryState);
        telemetryDispatch({ type: 'SET_DIRTY', value: false });
    }

    const handleUploadClick = () => {
        setShowDialog(true);
    }

    const handleFileUpload = (file) => {
        if (file) {
            const reader = new FileReader();

            reader.onload = function(event) {
                try {
                    const data = JSON.parse(event.target.result);
                    if (data) {
                        telemetryDispatch({
                            type: 'IMPORT_DATA',
                            value: data
                        });
                    }
                } catch (error) {
                    alert("Unable to parse program file.");
                }
            };
            
            reader.readAsText(file);
        }
        handleDialogClose();
    }

    const handleInspectClick = () => {
        console.log(telemetryState);
    }

    const handlePrint = () => {
        buildElementTable();
        setShowPrint(true);
    }

    const handlePrintClose = () => {
        setShowPrint(false);
    }

    const handleProgramPrint = () => {
        try {
            window.print();
        } catch (error) {}        
    }

    const buildElementTable = () => {
        const table = new Map();
        Object.entries(telemetryState.songAnnotations).forEach(([key, value]) => {
            const numKey = parseInt(key);
            if (table.has(parseInt(numKey))) {
                table.get(numKey).song.push(value.note);
            } else {
                table.set(numKey, {element: [], song: [value.note]});
            }
        });

        telemetryState.programElements.forEach(el => {
            if (table.has(el.time)) {
                table.get(el.time).element.push({type: el.type, note: el.note, elements: el.elements});
            } else {
                table.set(el.time, {element: [{type: el.type, note: el.note, elements: el.elements}], song: []});
            }
        });

        const sortedKeys = [...table.keys()].sort((a, b) => a-b);
        const sortedTable = sortedKeys.map(key => {
            return {key: key, value: table.get(key)};
        })

        setElementTable(sortedTable);
    }

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
  
      const files = Array.from(e.dataTransfer.files);
      handleFileUpload(files[0]);
    };

    const handleShowGuide = () => {
        setShowGuide(true);
    }

    const handleCloseGuide = () => {
        setShowGuide(false);
    }

    return (
        <>
        <ButtonGroup color="white" variant="contained">
            <Tooltip title="Download Program"><Button aria-label="Download Program" onClick={handleDownloadClick} className={`${telemetryState.dirty ? 'save-alert' : ''}`}><DownloadIcon /></Button></Tooltip>
            <Tooltip title="Import Program">
                <Button
                    aria-label="Import Program"
                    onClick={handleUploadClick}
                ><UploadIcon /></Button></Tooltip>
            <Tooltip title="Print Program"><Button aria-label="Print Program" onClick={handlePrint}><PrintIcon /></Button></Tooltip>
            <Tooltip title="Show application guide"><Button aria-label="Show application guide" onClick={handleShowGuide}><QuestionMarkIcon /></Button></Tooltip>
            <Tooltip title="Inspect Telemetry Data"><Button value="inspect" aria-label="Inspect Telemetry Data" onClick={handleInspectClick}><SearchIcon /></Button></Tooltip>
        </ButtonGroup>

        <Dialog open={showDialog} onClose={handleDialogClose}>
            <DialogTitle>Import Program</DialogTitle>
            <DialogContent>
                <Button
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    fullWidth
                    variant={isDragging ? "contained" : "outlined"}
                    size="large"
                    sx={{height: '200px', borderStyle: 'dashed'}}
                >Drop program file to import
                </Button>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleDialogClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={showPrint}
            onClose={handlePrintClose}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} id="print-header">
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handlePrintClose}
                    aria-label="Close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Print Program
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleProgramPrint}>
                        Print
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent id="print-content">
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{width: '70%'}}><Typography variant="h6">{telemetryState.metadata.programName}</Typography></TableCell>
                            <TableCell sx={{width: '30%', textAlign: 'right'}}>
                                <Chip variant="outlined" label={telemetryState.metadata.programType} />&nbsp;
                                <Chip variant="outlined" label={telemetryState.metadata.programLevel} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Song Title: {telemetryState.metadata.songTitle}</TableCell>
                            <TableCell>Song Artist: {telemetryState.metadata.songArtist}</TableCell>
                            <TableCell>Song Length: {convertSecondsToTimeString(telemetryState.metadata.songLength)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <TableContainer component={Box} id="print-table">
                    <Table size="small" className="elements-table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '125px'}}>Time</TableCell>
                                <TableCell sx={{width: '400px'}}>Song</TableCell>
                                <TableCell>Element</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            elementTable.map(({key, value}) => {
                                return (
                                    <TableRow>
                                        <TableCell>{convertSecondsToTimeString(key)}</TableCell>
                                        <TableCell>{value.song.map(note => <p>{note}</p>)}</TableCell>
                                        <TableCell>{value.element.map((el, i) => {
                                            return (
                                                <Box key={`element-${i}`} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                    <Box sx={{paddingRight: '15px'}}><strong>{el.type}</strong></Box>
                                                    <div className="print-table-elements-skills">
                                                    {el.elements.map((e, n) => {
                                                        return (<div key={`skill-${i}-${n}`}>{e.code !== '' ? `${e.code} - ` : ''}{e.name}</div>)
                                                    })}
                                                    <p className="print-table-elements-note">{el.note}</p>
                                                    </div>
                                                </Box>
                                            )
                                        })}</TableCell>
                                    </TableRow>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <RinkView rinkSize={telemetryState.metadata.rinkSize} />
            </DialogContent>
        </Dialog>
        <Guide open={showGuide} onClose={handleCloseGuide} />
        </>
    )
}

const RinkView = ({rinkSize}) => {
    const { state: appState } = useAppContext();
    const svgContent = appState.paper?.exportSVG({ matchShapes: true, asString: true });
    return (
        <div id="print-rink-wrapper">
            <div id="rink-container" className={rinkSize} dangerouslySetInnerHTML={{ __html: svgContent }} />
            <div id="print-key">
                <Box><StarIcon /> Start program</Box>
                <Box><ReportIcon /> End program</Box>
                <Box><CircleIcon /> Element</Box>
                <Box><WarningIcon /> Song queue</Box>
            </div>
        </div>
    );

}