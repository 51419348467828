import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ElementChip } from './ElementChip';
import { useTelemetryContext } from '../../context/TelemetryContext';
import { Spins } from '../../lib/Elements';

export function SpinPicker({index}) {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [elements, setElements] = useState(telemetryState.programElements[index].elements);

    const handleShowMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setShowMenu(true);
    }

    const themeColor = 'spin';

    const handleDelete = (elIndex) => {
        const els = elements.filter((v, i) => {
            return i !== elIndex;
        });
        setElements(els);
        const myElements = [...telemetryState.programElements];
        myElements[index].elements = els;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    const handleUpdateElements = (els) => {
        setElements(els);
        const myElements = [...telemetryState.programElements];
        myElements[index].elements = els;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    return (
        <Stack direction='row' className="element-card-elements">
            {elements.map((el, i) => {
                return (
                    <ElementChip
                        element={el}
                        programIndex={index}
                        elementIndex={i}
                        onDelete={() => handleDelete(i)}
                        themeColor={themeColor}
                        key={`element-${i}`}
                    />
                )
            })}
            {elements.length < 5 && <AddCircleIcon color={themeColor} onClick={handleShowMenu} />}
            <ElementPopover anchorEl={anchorEl} open={showMenu} setOpen={setShowMenu} elements={elements} setElements={handleUpdateElements} />
        </Stack>
    );
}

function ElementPopover({anchorEl, open, setOpen, elements, setElements}) {
    const handleClose = () => {
        setOpen(false);
    }

    const buildMenuItems = () => {
        const items = [];
        Spins.forEach((value, key, index) => {
            items.push(<MenuItem dense onClick={() => { handleElementClick(key, value) }} key={`${key}-${value.code}`}><span className="element-label">{value.code}</span> {key}</MenuItem>);
        });

        return items;
    }

    const handleElementClick = (key, value) => {
        const myElements = [...elements, {name: key, ...value}];
        setElements(myElements);
        setOpen(false);
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            sx={{maxHeight: '500px'}}
            children={buildMenuItems()}
        >
        </Menu>
    )
}