import { Layer, Path } from 'paper';

export const Program = (project) => {
    const program = new Layer({name: "Program"});

    const programPath = new Path({
        name: 'Program',
        strokeColor: '#82B0D9',
        strokeWidth: 4,
    });

    const startToken = new Path.Star({
        name: 'Start Token',
        center: [0, 0],
        points: 5,
        radius1: 6,
        radius2: 12,
        fillColor: '#FFD333',
        strokeColor: '#CCA000',
        strokeWidth: 2
    }).rotate(180);

    const endToken = new Path.RegularPolygon({
        name: 'End Token',
        center: [0, 0],
        sides: 8,
        radius: 12,
        fillColor: '#E00000',
        strokeColor: '#7A0000',
        strokeWidth: 2
    });

    program.updateTokens = () => {
        if (programPath.segments.length > 0) {
            startToken.visible = true;
            endToken.visible = true;
            startToken.position = programPath.firstSegment.point;
            endToken.position = programPath.lastSegment.point; 
        }
    }

    program.addChildren([programPath, startToken, endToken]);

    program.deleteEvent = () => {
        programPath.removeSegments();
        startToken.visible = false;
        endToken.visible = false;
        
        project.updateTelemetry({type: 'UPDATE_PROGRAM_PATH', value: programPath.exportJSON({asString: false})});
        project.updateTelemetry({type: 'UPDATE_METADATA', value: { programLength: programPath.length / programPath.project.globalScale }});
    }

    project.addLayer(program);
}