import { Group, Layer } from 'paper';

import { SegmentControl } from '../../lib/interactions/editMouseEvents';
import { findNearestPoint } from '../../lib/PathHelpers';

export const Edit = (project) => {
    const edit = new Layer({name: "Edit"});

    const editGroup = new Group({
        name: 'editGroup',
        strokeColor: 'black',
        strokeWidth: 5
    });
    edit.addChild(editGroup);

    edit.deleteEvent = () => {
        const deleteSegments = editGroup.children.filter(el => {
            return el.selected;
        });

        const programPath = project.layers['Program'].children['Program'];

        deleteSegments.forEach(seg => {
            const np = findNearestPoint(seg.position, programPath.segments);
            const sib1 = np.previousSibling;
            const sib2 = np.nextSibling;
            seg.remove();
            np.remove();
            project.layers['Program'].children['Program'].smooth({
                type: 'catmull-rom',
                factor: 0, 
                from: sib1,
                to: sib2
            });
        });

        project.updateTelemetry({type: 'UPDATE_PROGRAM_PATH', value: programPath.exportJSON({asString: false})});
        project.updateTelemetry({type: 'UPDATE_METADATA', value: { programLength: programPath.length / project.globalScale }});
    }

    edit.addSegmentControls = () => {
        const programPath = project.layers['Program'].children['Program'];
        editGroup.removeChildren();
        programPath.segments.forEach(seg => {
            editGroup.addChild(SegmentControl(seg, project));
        });
        editGroup.bringToFront();
    }

    project.addLayer(edit);
}