import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Guide = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth='lg'
            fullWidth={true}
        >
            <DialogTitle>Ice Flow Choreographer User Guide</DialogTitle>
            <DialogContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>Sales Pitch</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        <p>Ice Flow Choreographer is a cutting-edge web application revolutionizing the world of figure skating program design. Designed with skaters, coaches, and choreographers in mind, Ice Flow Choreographer streamlines the process of creating captivating and precisely timed figure skating programs.</p>

                        <Typography variant="h5">Key Features:</Typography>
                        <ul>
                            <li>Effortless Program Design: Seamlessly design figure skating programs with intuitive tools that allow you to draw the skater's path on the rink and visualize program elements with precision.</li>

                            <li>Dynamic Music Integration: Easily load and synchronize music with your program, ensuring seamless transitions and perfectly timed musical cues.</li>

                            <li>Tailored Program Customization: Choose from a variety of program types, from Singles Free Skate to Adult Singles Free Skate, and customize your program with ease.</li>

                            <li>Element Selection and Placement: Select and place elements within your program with precision, ensuring optimal flow and impact on the ice.</li>

                            <li>Visualize Program Dynamics: Visualize the placement and timing of elements along the skate path, allowing you to fine-tune every aspect of your program for maximum effect.</li>

                            <li>File Management and Printing: Save and load program files effortlessly, and print program information for easy reference and sharing.</li>
                        </ul>

                        <p>Ice Flow Choreographer empowers skaters and coaches alike to unleash their creativity and elevate their performances to new heights. Whether you're a seasoned competitor or just starting your skating journey, Ice Flow Choreographer is your ultimate companion for crafting unforgettable skating programs.</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>Overview</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Ice Flow Choreographer (IFC) is organized around three main areas: the Program Canvas, the Program Elements,  and the Program Audio. The Program Canvas displays the visualization of the program, allowing you to draw the program path (the path the skater will follow), see where program elements and musical cues will occur along the path. The Program Elements panel is where you set the type of program you are creating (Singles, Pairs, Ice Dance, Free or Short program, and Level), name the program, and select which elements will be performed and when. The Program Audio panel is where you set the music for the program, allowing you to upload the song and create musical cues. For the most part, these three areas can be used independently, allowing you, the choreographer, to use as much or as little of the application as you need.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>File Controls</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                        IFC operates entirely in your browser. These means that your data is secure and there is no chance for your data to be stolen off our server. This also means that if you forget to export your program there will be no way for you to recover it because it does not live on our server. Be sure to export your program often to minimize data loss when the application page is refreshed.
                        </p>
                        <p><strong>Program Export</strong></p>
                        <p>Allows you to download a program file that you can use to import your program into IFC. The export file contains all the information needed to load all parts of the program, including the audio track. It is not human-readable.</p>
                        <p><strong>Program Import</strong></p>
                        <p>Allows you to import a program file created by the export function.</p>
                        <p><strong>Program Print</strong></p>
                        <p>Allows you to print your program in a format that is readable and useful for taking to the ice rink. The canvas view used in the print will reflect the state of the Program Canvas at the time the print button is pressed.</p>
                        <p><strong>Show User Guide</strong></p>
                        <p>If you're reading this guide, you have figured out what this button does.</p>
                        <p><strong>Inspect Program Data</strong></p>
                        <p>This displays the current state of the program data in the browser's developer console. Useful for debugging the application during development, not so useful otherwise. You can ignore this button. It will go away eventually.</p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>Program Canvas</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                        The Program Canvas is where the program can be visualized. It allows for drawing and editing of the skater's path over the ice surface (program path), showing hockey lines, layout grid, and program elements and musical cues along the program path. The Program Canvas also shows some telemetry data for the program: view and set the average speed of the skater, how long it will take the skater to complete the program as drawn, the song length, and the total distance covered during the program.
                        </p>
                        <p><strong>Freehand Mode</strong></p>
                        <p>Allows you to draw on the canvas using your mouse, graphic table, or trackpad. The path will be automatically smoothed as you draw. Paths are intended to be drawn from beginning to end. Breaks in the path will be connected to the end of the existing path.</p>
                        <p><strong>Line Mode</strong></p>
                        <p>Allows you to draw on the canvas using straight lines. The path will be automatically smoothed as you draw. Paths are intended to be drawn from beginning to end. Breaks in the path will be connected to the end of the existing path.</p>
                        <p><strong>Edit Mode</strong></p>
                        <p>Allows you to edit the program path drawn with Freehand and Line mode. Edit mode will display the path anchor points. Click and drag on an anchor to reposition it. Double-click on one or more anchors to select them for deletion. The path will be automatically smoothed as you edit.</p>
                        <p><strong>Delete</strong></p>
                        <p>In Freehand and Line modes, delete will clear the entire program path. In Edit mode, delete will remove just the selected anchor points. You will be prompted for confirmation before deleting. <strong>Deletions can not be undone.</strong></p>
                        <p><strong>Toggle Grid</strong></p>
                        <p>Shows or hides the Grid Layer. The Grid Layer is a non-interactive overlay that displays a grid with lines spaced every 5 feet.</p>
                        <p><strong>Toggle Hockey Lines</strong></p>
                        <p>Shows or hides the Hockey Lines Layer. The Hockey Lines Layer is a non-interactive overlay that displays simplified hockey lines positioned according to NHL specifications.</p>
                        <p><strong>Toggle Program Elements</strong></p>
                        <p>Shows or hides the Program Elements Layer. The Program Elements Layer is a non-interactive overlay that displays the elements from the Program Elements along the program path. If no program path exists no elements will be drawn. Elements drawn will be represented as either a circle, for stationary elements like spins, or a wide line for elements that travel across the ice. Elements are color-coded to match their element in the Program Elements section. The length of an element is determined by the element's duration (see Program Elements section for more details on element duration).</p>
                        <p><strong>Toggle Audio Cues</strong></p>
                        <p>Show or hides the Audio Cues Layer. The Audio Cues layer is a non-interactive overlay that displays the audio cues from the Program Audio section. Audio cues are represented as green triangles along the program path.</p>
                        <p><strong>Avg. Speed</strong></p>
                        <p>Shows the  average skating speed used for program length and element placement calculations. Click to select a new value from 1mph - 15mph. The default is 8mph. This value is arbitrary and should not be taken as authoritative or even particularly well-informed.</p>
                        <p><strong>Program Length</strong></p>
                        <p>Shows the amount of time it should take the skater to complete the program based on the average speed and program elements defined.</p>
                        <p><strong>Song Length</strong></p>
                        <p>Shows the length of the loaded song. If no song is loaded the value will be blank.</p>
                        <p><strong>Rink Size</strong></p>
                        <p>The default rink size is 200' x 100' with 28' corner radii. These dimensions are largely a programming convenience and slightly larger than the maximum rink dimensions recommended by the ISU of 196' 10" x 98' 5" (60m x 30m).</p>
                        <p>There are four standard rink sizes supported:
                            <ul>
                                <li>Hockey - U.S.A. (200 ft. x 85 ft.)</li>
                                <li>Hockey - Intl. ( 61 m x 30 m)</li>
                                <li>ISU - Max. (60 m x 30 m)</li>
                                <li>ISU - Min. (56 m x 26 m)</li>
                            </ul>
                        </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>Program Elements</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                        The Program Elements panel is where you define which program type and level you are designing along with the timing and selection of elements. Program requirements will be loaded based on program type and level according to USFSA rules. Custom program has no requirements but is limited to 10 minutes.
                        </p>
                        <p><strong>Program Type</strong></p>
                        <p>Click to select the program type: Singles Free, Singles Short, Adult Singles Free, Custom.</p>
                        <p><strong>Program Level</strong></p>
                        <p>Click to select the program level: Pre-preliminary - Senior, Pre-bronze - Gold, Custom.</p>
                        <p><strong>Program Name</strong></p>
                        <p>Adds a name to the program.</p>
                        <p><strong>Program Type</strong></p>
                        <p>Click to select the program type: Singles Free, Singles Short, Adult Singles Free, Custom.</p>
                        <p><strong>Elements</strong></p>
                        <p>This is where you will define which elements and the timing of those elements within the program. The header will show program requirements based on the program type and level: program length plus time deltas, maximum number of scored elements, maximum number of jumps (includes combos, sequences, and solo jumps), maximum number of spins (includes combos), maximum number of step sequences (includes step and choreo sequences).</p>
                        <p><strong>Add Element</strong></p>
                        <p>Add an element to the program. The element types are based on the scorable element types for competition: jumps, spins, and step sequences. Other is for anything else you want to time.</p>
                        <p><strong>Element Time</strong></p>
                        <p>Click to select the timing of the element from 0:00 to the maximum for the program type and level. Also shows the total duration for all skills performed during the element (how long the element takes to execute). Elements will be automatically sorted after selecting the time.</p>
                        <p><strong>Add Skill</strong></p>
                        <p>Click to select the skill to be executed based on the type of element. The skills are based on the USFSA rules for each type. Only skills that have a computer code were included, except for half jumps which are scored for some adult levels. Element variations that share a computer code are combined into the more common skill (e.g. inside axel and axel).</p>
                        <p><strong>Skill</strong></p>
                        <p>Skill chips include the computer code for the skill and the name. All skills have a default duration, an estimate of how long the skill takes to execute. For jumps this equates to 0.5 seconds per revolution, spins have a default duration of 5 seconds, step sequences have a default duration of 10 seconds, all other skills have a default duration of 0. The sum of all skill durations for an element is shown next to the element timing. The duration of a skill may be customized by clicking on the skill chip and selecting a new duration from 0.5 - 30 seconds in half second increments.</p>
                        <p>Jump elements are limited to 5 skills. Spin elements are limited to 4 skills. Step sequences are limited to either Step Sequence or Choreo Sequence. Other elements have no skill limit.</p>
                        <p><strong>Delete</strong></p>
                        <p>Removes the element from the program.</p>
                        <p><strong>Add Note</strong></p>
                        <p>Click to show the note field and add/edit an element note.</p>
                        <p><strong>Program Validation</strong></p>
                        <p>There is currently no validation for programs. This means it is your responsibility as the choreographer to make sure the program designed follows competition or testing rules. The application will also allow you to define nonsensical programs with multiple concurrent elements, elements whose timing overlaps. Validation will be added in the future, but for now be careful.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <strong>Program Audio</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                        The Program Audio panel is where you load the song, and record the title and artist. Any audio format playable in the browser should work, but MP3 and MP4 are safe. Audio tracks purchased from a music service and requiring authentication will most likely not work.
                        </p>
                        <p><strong>Title</strong></p>
                        <p>Add a title for the song. Unfortunately there's no easy way to pull this information from the audio file, so you'll have to supply it.</p>
                        <p><strong>Artist</strong></p>
                        <p>Add an artist for the song. Unfortunately there's no easy way to pull this information from the audio file, so you'll have to supply it.</p>
                        <p><strong>Length</strong></p>
                        <p>This is loaded directly from the uploaded audio file.</p>
                        <p><strong>Audio Upload</strong></p>
                        <p>Pick a song file to use in the program. Any file format playable in the browser should work, but the following file formats have been tested and verified to work: .aac, .flac, .m4a, .mp3, .ogg, .wav. Audio tracks purchased from a music service and requiring authentication will most likely not work.</p>
                        <p><strong>Audio Controls</strong></p>
                        <p>Control audio playback. Play, pause, stop, scrub, set volume, playback speed, and download audio track.</p>
                        <p><strong>Add Annotation</strong></p>
                        <p>Add an audio annotation (cue) at the current playback location. Only one timing cue is allowed per time index. Timing cues are limited to whole seconds (no fractions). Audio annotations are automatically sorted as they are created.</p>
                        <p><strong>Add Program Element from Audio Cue</strong></p>
                        <p>Add a corresponding program element at the same time index as the audio cue.</p>
                        <p><strong>Start Playback from Cue</strong></p>
                        <p>Play the audio track starting at the cue time index.</p>
                        <p><strong>Audio Annotation</strong></p>
                        <p>Add a note to the audio cue.</p>
                        <p><strong>Remove Audio Cue</strong></p>
                        <p>Remove the audio cue from the program.</p>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Guide;