import { Group, Layer, Path, Point, Rectangle, Size } from 'paper';

import RinkDimensions from '../../lib/RinkDimensions';

export const Boundary = (project) => {
    const rinkBoundary = new Layer({name: "Boundary"});
    const rinkBorderGroup = new Group({name: 'RinkBorder'});
    rinkBoundary.addChild(rinkBorderGroup);

    // Draw the outline of the ice rink with rounded corners
    const startPoint = new Point(0, 0);
    const rinkSize = new Size(project.rinkWidth, project.rinkHeight);
    const cornerRadius = new Size(project.rinkRadius, project.rinkRadius);
    const rinkBorder = new Rectangle(startPoint, rinkSize);
    const rink = new Path.Rectangle(rinkBorder, cornerRadius);
    rink.name = "Rink";
    rink.fillColor = '#FFFFFF';

    rink.onFrame = () => {
        //Set rink dimensions
        const width = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').width * project.globalScale;
        const height = RinkDimensions.get(project?.telemetryState?.metadata?.rinkSize ?? 'default').height * project.globalScale;
        
        rink.set({
            from: [0, 0],
            to: [width, height]
        });

        //Set visible layers
        project.layers["Grid"].visible = project.appConfig.viewMode?.includes('grid') ?? true;
        project.layers["HockeyLines"].visible = project.appConfig.viewMode?.includes('lines') ?? true;
        project.layers["Program Elements Layer"].visible = project.appConfig.viewMode?.includes('elements') ?? true;
        project.layers["Audio Elements Layer"].visible = project.appConfig.viewMode?.includes('audio') ?? true;
        project.layers['Run'].visible = project.drawMode === 'run';
    }

    rink.closePath();
    rinkBorderGroup.addChild(rink);

    project.addLayer(rinkBoundary);
}