import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { useTelemetryContext } from '../../context/TelemetryContext';

export const ElementChip = (props) => {
    const { state: telemetryState, dispatch } = useTelemetryContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handlePopoverOpen = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    const handlePopoverChange = (value) => {
        const myElements = [...telemetryState.programElements];
        myElements[props.programIndex].elements[props.elementIndex].time = value;
        dispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    return (
        <>
        <Chip
            label={`${props.element.code} ${props.element.name}`}
            variant="filled"
            color={props.themeColor}
            onDelete={props.onDelete}
            sx={{marginRight: '5px', fontWeight: 'bold'}}
            onClick={handlePopoverOpen}
        />
        <TimePopover
            anchorEl={anchorEl}
            el={props.element}
            open={open}
            setOpen={handlePopoverOpen}
            setClose={handlePopoverClose}
            handleChange={handlePopoverChange}
        />
        </>
    )
}

function TimePopover({anchorEl, el, open, setClose, handleChange}) {
    const [value, setValue] = useState(el.time);

    const handleValueChange = (event) => {
        setValue(event.target.value);
    }

    const handlePopoverClose = () => {
        setClose();
    }

    const handleChangeCommitted = () => {
        handleChange(value);
    }

    return (
        <Popover
            id="time-index-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            sx={{padding: '13px 25px 10px'}}
        >
            <Box sx={{padding: '15px 15px 5px'}}>
                <Typography><strong>Element duration: {value} sec.</strong></Typography>
                <Slider
                    min={0}
                    max={30}
                    step={.5}
                    sx={{width: '300px'}}
                    size='small'
                    valueLabelDisplay="auto"
                    value={value}
                    marks
                    onChange={handleValueChange}
                    onChangeCommitted={handleChangeCommitted}
                />
            </Box>
        </Popover>
    )
}