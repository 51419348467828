import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
    paper: undefined,
    wavesurfer: null,
    canvasVisible: null,
    globalScale: 7,
    viewMode: ['grid', 'lines', 'elements', 'audio'],
}

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);

const reducer = (state, action) => {
    let newState = {};
    switch (action.type) {
        case 'SET_PAPER_PROJECT':
            newState = { ...state, paper: action.value };
            break;
        case 'SET_CANVAS_VISIBILITY':
            newState = { ...state, canvasVisible: action.value };
            break;
        case 'SET_WAVESURFER':
            newState = { ...state, wavesurfer: action.value };
            break;
        case 'SET_VIEW_MODE':
            newState = { ...state, viewMode: action.value };
            break;
        default:
            newState = state;
    }

    newState.paper.updateAppConfig(newState);
    return newState;
};

export default AppContext;