export const convertSecondsToTimeString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);
    const paddedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

    return `${minutes}:${paddedSeconds}`;
}

export const convertTimeStringToSeconds = (time) => {
    const [minutes, seconds] = time.split(':');
    return (minutes * 60) + seconds;
}

export const sortNumeric = (a, b) => a-b;