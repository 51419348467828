export function calculatePerpendicularAngle(point1, point2) {
    // Calculate the vector between the two points
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;

    // Calculate the perpendicular vector by rotating the original vector by 90 degrees
    const perpendicularDx = -dy;
    const perpendicularDy = dx;

    // Calculate the angle of the perpendicular vector using Math.atan2
    const angle = Math.atan2(perpendicularDy, perpendicularDx);

    // Convert the angle from radians to degrees
    const angleDegrees = angle * (180 / Math.PI);

    // Ensure the angle is in the range [0, 360)
    const positiveAngle = (angleDegrees < 0) ? angleDegrees + 360 : angleDegrees;

    return positiveAngle;
}

export function distancePointToLine(pointC, pointA, pointB) {
    const {x: x0, y: y0} = pointC;
    const {x: x1, y: y1} = pointA;
    const {x: x2, y: y2} = pointB;

    // Calculate coefficients of the line equation Ax + By + C = 0
    const A = y2 - y1;
    const B = x1 - x2;
    const C = x2 * y1 - x1 * y2;

    // Calculate distance using the formula
    const distance = (A * x0 + B * y0 + C) / Math.sqrt(A * A + B * B);

    return distance;
}

export function distancePointToMidpoint(pointC, pointA, pointB) {
    // Calculate the midpoint of the line segment AB
    const midpoint = [(pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2];

    // Calculate the vector from the midpoint to point C
    const vectorMC = [pointC.x - midpoint[0], pointC.y - midpoint[1]];

    // Calculate the vector representing the line segment AB
    const vectorAB = [pointB.x - pointA.x, pointB.y - pointA.y];

    // Calculate the dot product of vectorMC and vectorAB
    const dotProduct = vectorMC[0] * vectorAB[0] + vectorMC[1] * vectorAB[1];

    // Calculate the length of vectorAB
    const lengthAB = Math.sqrt(vectorAB[0] ** 2 + vectorAB[1] ** 2);

    // Calculate the parallel distance as the absolute value of the dot product divided by the length of vectorAB
    const parallelDistance = dotProduct / lengthAB;

    return parallelDistance;
}

export function oppositeAngle(initialAngle) {
    let opposite = initialAngle;
    if (initialAngle < 0) {
        opposite = 180 + initialAngle;
    } else if (initialAngle > 0) {
        opposite = initialAngle - 180;
    }

    return opposite;
}

export function findNearestPoint(targetPoint, points) {
    // Initialize variables to keep track of the nearest point and its distance
    let nearestPoint = null;
    let minDistance = Infinity;

    // Iterate through each point in the list
    for (const point of points) {
        // Calculate the distance between the target point and the current point
        const distance = calculateDistance(targetPoint, point.point);

        // Update the nearest point and its distance if the current point is closer
        if (distance < minDistance) {
            nearestPoint = point;
            minDistance = distance;
        }
    }

    // Return the nearest point
    return nearestPoint;
}

export function calculateDistance(point1, point2) {
    // Calculate the Euclidean distance between two points
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
}