import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import Typography from '@mui/material/Typography';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import GestureIcon from '@mui/icons-material/Gesture';
import PolylineIcon from '@mui/icons-material/Polyline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useAppContext } from '../../context/AppContext';

export const CanvasDrawMode = () => {
    const { state: appConfig } = useAppContext();
    const [drawMode, setDrawMode] = useState('gesture');
    const [showDialog, setShowDialog] = useState(false);

    const handleMode = ( event, mode) => {
        setDrawMode(mode);
        appConfig.paper.API.setDrawMode(mode);
    }

    const handleDeleteClick = () => {
        setShowDialog(true);
    }

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const handleDeleteEvent = () => {
        appConfig.paper.API.delete();
        setShowDialog(false);
    }

    return (
        <>
        <ToggleButtonGroup value={drawMode} exclusive color="primary" onChange={handleMode}>
            <Tooltip title="Toggle Run-Through"><ToggleButton value="run"><DirectionsRunIcon /></ToggleButton></Tooltip>
            <Tooltip title="Freehand Mode"><ToggleButton value="gesture" aria-label="Freehand Mode"><GestureIcon /></ToggleButton></Tooltip>
            <Tooltip title="Line Mode"><ToggleButton value="line" aria-label="Line Mode"><PolylineIcon /></ToggleButton></Tooltip>
            <Tooltip title="Edit Mode"><ToggleButton value="edit" aria-label="Edit Mode"><ModeEditIcon /></ToggleButton></Tooltip>
            <Tooltip title={`Delete ${drawMode === 'edit' ? 'segments' : 'path'}`}><Button value="delete" aria-label="Delete Path/Segments" onClick={handleDeleteClick}><DeleteForeverIcon color="warning" /></Button></Tooltip>
        </ToggleButtonGroup>
        <Dialog open={showDialog} onClose={handleDialogClose}>
            <DialogTitle>Delete {drawMode === 'edit' ? 'segments' : 'path'}?</DialogTitle>
            <DialogContent>
                <Typography> This action can not be undone.</Typography>
                <Typography>Are you sure you want to delete the {drawMode === 'edit' ? 'selected segments' : 'path'}?</Typography>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleDialogClose}>
                Cancel
            </Button>
            <Button onClick={handleDeleteEvent}>Delete</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}