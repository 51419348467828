import React, { useState } from 'react';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MusicOffIcon from '@mui/icons-material/MusicOff';

import { convertSecondsToTimeString } from '../../lib/DataHelpers';
import { useTelemetryContext } from '../../context/TelemetryContext';
import { useAppContext } from '../../context/AppContext';

export const AudioAnnotations = () => {
    const { state: telemetryState, dispatch: telemetryDispatch } = useTelemetryContext();
    const { state: appState } = useAppContext();
    const [isReady, setIsReady] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [dialogKey, setDialogKey] = useState(null);
    const [dialogNote, setDialogNote] = useState('');
    const [showElementDialog, setShowElementDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    if (appState.wavesurfer) {
        appState.wavesurfer.on('ready', () => {
            setIsReady(true);
        });
    
        appState.wavesurfer.on('destroy', () => {
            setIsReady(false);
        });
    }

    const getCurrentTime = () => {
        return Math.floor(appState.wavesurfer.getCurrentTime());
    }

    const addAnnotation = () => {
        const currentAnnotations = {...telemetryState.songAnnotations};
        const currentTime = `${getCurrentTime()}`;
        const timeString = convertSecondsToTimeString(getCurrentTime());
        if (!Object.keys(currentAnnotations).includes(currentTime)) {
            setShowDialog(true);
            setDialogKey(timeString);
        } else {
            const element = document.getElementById(`row-${currentTime}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'end'});
                const input = element.querySelector('input[type="text"]');
                if (input) {
                    input.focus();
                    input.setSelectionRange(input.value.length, input.value.length);
                }
            }
        }
    }

    const deleteAnnotation = (key) => {
        const currentAnnotations = {...telemetryState.songAnnotations};
        delete currentAnnotations[key];
        updateAnnotations(currentAnnotations);
    }

    const updateAnnotationNote = (key, value) => {
        const currentAnnotations = {...telemetryState.songAnnotations};
        currentAnnotations[key].note = value;
        updateAnnotations(currentAnnotations);
    }

    const updateAnnotations = (annotations) => {
        telemetryDispatch({type: 'UPDATE_ANNOTATIONS', value: annotations});
    }

    const playAnnotation = (timeIndex) => {
        appState.wavesurfer.setTime(timeIndex - 1);
        appState.wavesurfer.play();
    }

    const handleDialogClose = () => {
        setShowDialog(false);
        setDialogKey(null);
        setDialogNote('');
    }

    const updateDialogNote = (value) => {
        setDialogNote(value);
    }

    const handleDialogSave = () => {
        const currentAnnotations = {...telemetryState.songAnnotations};
        const currentTime = getCurrentTime();
        currentAnnotations[currentTime] = {note: dialogNote, time: dialogKey};
        updateAnnotations(currentAnnotations);
        handleDialogClose();
    }

    const handleShowElementDialog = (event) => {
        setShowElementDialog(true);
        setAnchorEl(event.currentTarget);
    }

    const handleCloseElementDialogClose = () => {
        setShowElementDialog(false);
    }

    const handleAddElement = (type, timeIndex) => {
        const myElements = [...telemetryState.programElements];
        myElements.push({time: timeIndex, type: type, note: '', elements: []});
        myElements.sort((a, b) => a.time - b.time);

        telemetryDispatch({type: 'UPDATE_PROGRAM_ELEMENTS', value: myElements});
    }

    const handleDeleteMusic = () => {
        telemetryDispatch({type: 'UPDATE_DECODED_AUDIO', value: null});
        telemetryDispatch({type: 'UPDATE_METADATA', value: {songLength: 0}});
        handleDeleteDialogClose();
    }

    const handleDeleteDialogClose = () => {
        setShowDeleteDialog(false);
    }

    const unloadMusic = () => {
        setShowDeleteDialog(true);
    }

    return (
        <>
            {isReady &&
            <TableContainer component={Paper} sx={{marginTop: '25px'}}>
                <Table size="small" padding="none" id="annotation-table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3} sx={{padding: '8px 16px'}}>
                                <Typography size='large' sx={{fontWeight: 'bold'}}>
                                    Audio Cues
                                </Typography>
                            </TableCell>
                            <TableCell sx={{padding: '8px 16px', whiteSpace: 'nowrap'}}>
                                <Tooltip title="Add annotation at time index">
                                    <Button aria-label="Add cue at time index" color="primary" size="small" onClick={addAnnotation} sx={{minWidth: '35px', width: '35px'}}><PlaylistAddIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Unload music"> 
                                    <Button aria-label="Unload music" color="warning" size="small" onClick={unloadMusic} sx={{minWidth: '35px', width: '35px'}}><MusicOffIcon /></Button>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{width: '30px'}}></TableCell>
                            <TableCell sx={{width: '100px'}}>Time</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell sx={{width: '30px'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.keys(telemetryState.songAnnotations).sort().map((key) => {
                        return (
                            <TableRow key={`annotation-${key}`} id={`row-${key}`}>
                                <TableCell><Tooltip title="Add program element at time index"><Button onClick={handleShowElementDialog} sx={{minWidth: '50px'}} value={key}><MoreTimeIcon /></Button></Tooltip></TableCell>
                                <TableCell><Tooltip title="Jump to time index and play"><Button startIcon={<SkipNextIcon />} onClick={() => { playAnnotation(key); }} sx={{minWidth: '50px', padding: '6px 0'}}>{telemetryState.songAnnotations[key].time}</Button></Tooltip></TableCell>
                                <TableCell><TextField hiddenLabel size="small" fullWidth onChange={(event) => updateAnnotationNote(key, event.target.value)} value={telemetryState.songAnnotations[key].note} /></TableCell>
                                <TableCell><Button size='small' onClick={() => { deleteAnnotation(key) }} color='primary'><RemoveCircleOutlineIcon /></Button></TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            }

            <Dialog open={showDialog} onClose={handleDialogClose}>
                <DialogTitle>Add cue at time index {dialogKey}</DialogTitle>
                <DialogContent>
                    <TextField hiddenLabel size="small" fullWidth onChange={(event) => updateDialogNote(event.target.value)} value={dialogNote} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>Unload audio?</DialogTitle>
                <DialogContent>
                    <Typography>You will need to upload a new audio track?</Typography>
                    <Typography>Are you sure you want to unload the audio file?</Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleDeleteDialogClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteMusic}>Unload audio</Button>
                </DialogActions>
            </Dialog>

            <Menu
                id="time-index-menu"
                anchorEl={anchorEl}
                open={showElementDialog}
                onClose={handleCloseElementDialogClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                sx={{maxHeight: '500px'}}
            >
                <MenuItem><Button color="jump" onClick={() => {handleAddElement('Jump', anchorEl.value)}} value='Jump' endIcon={<AddCircleIcon />}>Jump</Button></MenuItem>
                <MenuItem><Button color="spin" onClick={() => {handleAddElement('Spin', anchorEl.value)}} value='Spin' endIcon={<AddCircleIcon />}>Spin</Button></MenuItem>
                <MenuItem><Button color="step" onClick={() => {handleAddElement('Step', anchorEl.value)}} value='Step' endIcon={<AddCircleIcon />}>Step</Button></MenuItem>
                <MenuItem><Button color="other" onClick={() => {handleAddElement('Other', anchorEl.value)}} value='Other' endIcon={<AddCircleIcon />}>Other</Button></MenuItem>
            </Menu>
        </>
    )
}